import ImageSizeError from "./errors";
import { changeFilenameExtension } from "./utilities";

export type ImageSize = {
  widthPixels?: number;
  heightPixels?: number;
}

type ImageCompressProperties = {
  maxBytes: number;
  qualityStep?: number;
}

type ImageConvertProperties = {
  type?: string;
  quality?: number;
  size?: ImageSize;
}

async function compress(file: File,
  {
    maxBytes,
    qualityStep = 0.1,
  }: ImageCompressProperties
): Promise<File> {
  let best = file;
  let lowQuality = 0.0;
  let highQuality = 1.0;
  let fileSize = file.size;
  const maxSteps = 1.0 / qualityStep;
  let step = 0;
  while (
    (
      step++ < maxSteps &&
      fileSize > maxBytes
    ) ||
    (highQuality - lowQuality) > qualityStep
  ) {
    const quality = (highQuality + lowQuality) / 2.0;
    const current = await convert(file, { type: file.type, quality });
    if (current.size <= maxBytes) {
      lowQuality += qualityStep;
      best = current;
    } else {
      highQuality -= qualityStep;
    }
  }

  if (best.size > maxBytes) {
    throw new ImageSizeError("The image is too large to compress.");
  }
  return best;
}

async function resize(file: File,
  {
    widthPixels,
    heightPixels,
  }: ImageSize
): Promise<ImageSize> {
  if (!Boolean(widthPixels) && !Boolean(heightPixels)) {
    throw new ImageSizeError("At least one of width or height must be specified.");
  }

  const bmp = await createImageBitmap(file);
  try {
    let width = widthPixels || bmp.width;
    let height = heightPixels || bmp.height;

    if (widthPixels === undefined) {
      width = width * (height / bmp.height);
    } else if (heightPixels === undefined) {
      height = height * (width / bmp.width);
    }

    return {
      widthPixels: width,
      heightPixels: height,
    };
  } finally {
    bmp.close();
  }
}

async function convert(file: File,
  {
    type = "image/jpeg",
    quality = 1.0,
    size,
  }: ImageConvertProperties
): Promise<File> {
  if (size) {
    if (!Boolean(size.heightPixels) && !Boolean(size.widthPixels)) {
      throw new ImageSizeError(
        "".concat(
          "The only valid options for size are leaving it undefined or ",
          "defining at least one value inside of it.",
        )
      );
    }
  }

  const bmp = await createImageBitmap(file);
  const height = size?.heightPixels || bmp.height;
  const width = size?.widthPixels || bmp.width;

  const canvas = document.createElement('canvas');
  canvas.height = height;
  canvas.width = width;

  try {
    const ctx = canvas.getContext('2d');
    if (ctx === null) {
      return file;
    }
    ctx.drawImage(bmp, 0, 0, width, height);

    const blob = await new Promise<Blob | null>(resolve =>
      canvas.toBlob(resolve, type, quality)
    );

    if (!blob) {
      return file;
    }
    const extension = type.split("/")[1];
    return new File([blob], changeFilenameExtension(file.name, extension), { type });
  } finally {
    bmp.close();
  }
}

export default function useImage() {
  return {
    convert,
    resize,
    compress,
  }
}