import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';

type TermsOfServiceProperties = {
    onAccept: () => void;
    onReject: () => void;
}

export function TermsOfService({ onAccept, onReject }: TermsOfServiceProperties) {

    return (
        <Container>
            <Row>
                <Col className="h1 text-center">
                    Terms of Service
                </Col>
            </Row>
            <Row>
                <Col className="h1 text-center mb-4">
                    TELAPORT INC.
                </Col>
            </Row>
            <Row>
                <Col className="h4">
                    <strong>IMPORTANT: Base Rules that Apply to All Orders</strong>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p><strong>For every package;</strong></p>
                    <ListGroup className='mb-4'>
                        <ListGroup.Item action>
                            At our sole and absolute discretion, we have the right to open and inspect any package and its contents for any reason.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            If any package contains items that violate our <u>Terms of Service</u> or are in violation of any local, state and/or Federal law, we reserve the right to contact the proper legal authorities and share the sender and recipient’s information; or if the item is  not illegal but against Terms of Service, we reserve the right to dispose of the item in the appropriate manner, with no compensation, and will possibly result in the sender and recipient’s accounts being penalized or banned from the platform.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will only receive packages that are delivered through UPS and from no other carrier.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            You agree not to use Telaport to send any dangerous items through Telaport, please refer to the UPS guidelines at <a href='https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/hazardous-materials-guide.page'>https://www.ups.com/us/en/support/shipping-support/shipping-special-care-regulated-items/hazardous-materials-guide.page</a>, which is incorporated by reference, and other US regulatory agencies such as the FAA and IATA, and TDG from the CCOHS for packages coming from or shipping to Canada.
                        </ListGroup.Item>

                        <h4><strong>Limitation of Liability</strong></h4>
                        <ListGroup.Item action>
                            As soon as a package leaves Telaport Inc. premises and/or received by the shipping company, we will not have any liability or responsibility for your goods.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            Telaport will only provide compensation for items <u>that are damaged or lost at our facility</u> and that are insured by the shipping company.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            The compensation shall be limited to from 1% to  a maximum of 10% of the value of goods valued over $100.00 USD and under $1000.00 USD,  and compensation from 25% and up to a maximum of 50% for goods valued under $100.00 USD, we will not provide any compensation for packages in orders valued over $1000.00 USD who have not selected the <em>Elite</em> tier service. You agree that we have the sole and absolute discretion to determine what compensation to pay for goods that are damaged at our facility.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            If a shipping label has not been purchased 168 hours after a Telaport trade has been approved by both parties, after arriving at a Telaport Location, we reserve the right to dispose of that package and its contents at our sole discretion.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            After both packages arrive at the facility and each of the service requirements have been completed and proper notifications and/or details have been sent to each of the parties, each of the parties have 72 hours to respond to the order, either <em>Confirm</em> or <em>Reject</em> the trade transaction. If no response is received within 72 hours of the aforementioned notice and the other party did not choose to <em>Reject</em>, we will automatically <em>Confirm</em> the trade transaction on the party’s behalf, allowing the other party to purchase a shipping label to receive the other parties sent item at the end destination.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            The service fee for <em>Lite, Basic, Pro, Elite</em>, and any other future offerings are <strong>non-refundable</strong>.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            The customer and each individual party are responsible for the shipping fees associated with shipping their packages to and from Telaport Premises.
                        </ListGroup.Item>
                        {/* Add more list items as needed */}
                    </ListGroup>
                    <h4 className='mb-4'><strong>Tiers</strong></h4>
                    <h4><em>Lite</em></h4>
                    <ListGroup>
                        <ListGroup.Item action>
                            We will notify the two parties included in an order of the time each of the two packages arrived at the facility once they have been scanned into our system.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide temporary storage for the package up to 168 hours after both parties have chosen <em>“Confirm”</em> after the package and service has been processed and notified to the parties via email or website notifications.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide different and varying rates, depending on end destination, as shipping options to the end destination.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            We will not provide compensation for lost, damaged, or stolen goods sent within the Lite tier.
                        </ListGroup.Item>
                    </ListGroup>
                    <h4><em>Basic</em></h4>
                    <ListGroup>
                        <ListGroup.Item action>
                            We will notify the two parties included in an order of the time each of the two packages arrived at the facility once they have been scanned into our system.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide temporary storage for the package up to 168 hours after both parties have chosen <em>“Confirm”</em> after the package and service has been processed and notified to the parties via email or website notifications.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide different and varying rates, depending on end destination, as shipping options to the end destination.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will not provide compensation for lost, damaged, or stolen goods sent within the Lite tier.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            We will send the purchasing party a message containing a reasonable assessment of the contents of the package in comparison to the included title, description, and images, if any images were included, provided in the Telaport Order.
                        </ListGroup.Item>
                    </ListGroup>
                    <h4><em>Pro</em></h4>
                    <ListGroup>
                        <ListGroup.Item action>
                            We will notify the two parties included in an order of the time each of the two packages arrived at the facility once they have been scanned into our system.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide temporary storage for the package up to 168 hours after both parties have chosen <em>“Confirm”</em> after the package and service has been processed and notified to the parties via email or website notifications.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide different and varying rates, depending on end destination, as shipping options to the end destination.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will not provide compensation for lost, damaged, or stolen goods sent within the Lite tier.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will send the purchasing party a message containing a reasonable assessment of the contents of the package in comparison to the included title, description, and images, if any images were included, provided in the Telaport Order.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            We will take 3-6 images of your item under reasonable lighting conditions and send the images to the purchasing party alongside the message in the previous point.
                        </ListGroup.Item>
                    </ListGroup>
                    <h4><em>Elite</em></h4>
                    <ListGroup>
                        <ListGroup.Item action>
                            We will notify the two parties included in an order of the time each of the two packages arrived at the facility once they have been scanned into our system.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide temporary storage for the package up to 168 hours after both parties have chosen <em>“Confirm”</em> after the package and service has been processed and notified to the parties via email or website notifications.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will provide different and varying rates, depending on end destination, as shipping options to the end destination.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will not provide compensation for lost, damaged, or stolen goods sent within the Lite tier.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will send the purchasing party a message containing a reasonable assessment of the contents of the package in comparison to the included title, description, and images, if any images were included, provided in the Telaport Order.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            We will take 3-6 images of your item under reasonable lighting conditions and send the images to the purchasing party alongside the message in the previous point.
                        </ListGroup.Item>
                        <ListGroup.Item action className='mb-4'>
                            Depending on an agreement made between Telaport and the purchasing party before paying for <em>Elite</em>. We will charge between 1% and 5% of the value of items insured with the shipper for over $1000.00.
                        </ListGroup.Item>
                    </ListGroup>
                    <h4><strong>Procedure for Filing a Claim for Damaged Packages at Telaport Inc.</strong></h4>
                    <p>
                        If you have received a package via Telaport Inc. that you believe was damaged at our facility and not during transit, please follow the procedure outlined below to file a claim.
                    </p>
                    <h4><strong>Step 1: Review the Arbitration Clause</strong></h4>
                    <p>
                        Before filing a claim, please review our arbitration clause. By submitting a claim, you agree that any disputes arising from your claim will be resolved through arbitration, rather than in court.
                    </p>
                    <h4><strong>Step 2: Timing for Submitting a Claim</strong></h4>
                    <p>
                        You must submit your claim within <strong>72 hours</strong> from the time the package arrives at its final destination. Claims submitted after this period will not be eligible for consideration.
                    </p>
                    <h4><strong>Step 3: Where to Send Your Claim</strong></h4>
                    <p>
                        All claims must be submitted via email to our dedicated claims department. Please send your claim to: Info@teleport.net
                    </p>
                    <h4><strong>Step 4: Information to Include in Your Claim</strong></h4>
                    <p>
                        To ensure your claim is processed smoothly, please include the following information in your email:
                    </p>
                    <ListGroup className='mb-4'>
                        <ListGroup.Item action>
                            <strong>Your Full Name</strong> and <strong>Contact Information</strong> (email address and phone number).
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            <strong>Order Number</strong> and <strong>Tracking Number</strong> of the affected package.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            A detailed <strong>Description of the Damage</strong> to your package.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            <strong>Photographic Evidence</strong> of the damage (photos of the package and the damaged items).
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            <strong>Date and Time</strong> the package was received.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            Any other relevant information or documentation that supports your claim.
                        </ListGroup.Item>
                    </ListGroup>
                    <h4><strong>Step 5: Claim Review Process</strong></h4>
                    <p>
                        Upon receiving your claim, our team will review the submitted information and may contact you for additional details or clarification. Please allow us up to <strong>10 business days</strong> to investigate your claim and provide you with an update on our findings and the next steps.
                    </p>
                    <h4><strong>Step 6: Resolution</strong></h4>
                    <p>
                        Once the review process is complete, we will inform you of the outcome of your claim. If your claim is validated, Telaport Inc. will provide instructions on how to proceed with compensation or replacement options, in accordance with our policies and the specifics of your case.
                    </p>
                    <p>
                        <strong>Dispute Resolution:</strong> In the event that you do not agree with our decision after you submit your claim for a lost or damage item(s) at our facility, you agree to abide by our dispute resolution procedures as outlined below:
                    </p>
                    <ListGroup className='mb-4'>
                        <ListGroup.Item action>
                            (a) MEDIATION: Prior to the institution of any legal action, the parties agree to participate, in good faith, in a mediation conference with a Florida Supreme Court Certified Mediator to be administered by the American Arbitration Association.  The parties shall agree to the selection of the mediator and agree to share the cost of the mediator equally. The mediation shall be scheduled within 45 days from the date that either party submits a written notice requesting the mediation conference to the other party. The notice shall be via U.S. Certified Mail or via an overnight carrier. The mediation conference shall be conducted via video conference, or in Tampa, Florida.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            (b) BINDING ARBITRATION:  In the event either party does not agree or fails to participate in a mediation conference, pursuant to paragraph (a) above, the parties agree to submit any and all disputes arising out of these by-laws to binding arbitration to be conducted by the American Arbitration Association under its Commercial Arbitration Rules and Mediation Procedures (<a href='https://www.adr.org/sites/default/files/Commercial-Rules_Web.pdf'>https://www.adr.org/sites/default/files/Commercial-Rules_Web.pdf</a>). The arbitration shall be conducted within 60 days from the date of the impasse of the mediation conference, or within 60 days from the date either party submits a Notice for Arbitration proceedings to the other party. The notice shall be via U.S. Certified Mail or via an overnight carrier.
                        </ListGroup.Item>
                        <ListGroup.Item action>
                            (c). ENFORCING ARBITRATION. Notwithstanding the foregoing, either party may seek an Order compelling arbitration with the Circuit Court of the 13th Judicial Circuit in and for Hillsborough County, Florida and waive the defenses of lack of personal and/or subject matter jurisdiction and/or forum of non-convenience.
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col className="text-center mb-4">
                    <Button
                        className="me-2"
                        onClick={onAccept}
                    >
                        Accept Terms
                    </Button>
                    <Button
                        variant='secondary'
                        onClick={onReject}
                    >
                        Close
                    </Button>
                </Col>
            </Row>

        </Container>
    );
};

export default TermsOfService;


