import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link45deg } from "react-bootstrap-icons";
import SpinnerButton, { SpinnerButtonProps } from "./SpinnerButton";

export type ShareButtonProperties = SpinnerButtonProps & {
  tooltip: string;
}

export default function ShareButton(
  {
    children,
    tooltip,
    onMouseLeave,
    ...props
  }: ShareButtonProperties
): JSX.Element {
  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div ref={ref}>
          <SpinnerButton
            variant="secondary"
            onMouseLeave={e => setTimeout(() => onMouseLeave && onMouseLeave(e), 100)}
            {...props}
            {...triggerHandler}
          >
            <Link45deg className="bi" /> {children}
          </SpinnerButton>
        </div>
      )}
    </OverlayTrigger>
  );
}