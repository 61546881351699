import { useNavigate } from "react-router-dom";
import FAQ from "./FAQ";
import Team from "./Team";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Authentication } from "../types";
import { URLS } from "../utils/constants";

const youtubeUrl = "https://www.youtube.com/@telaportofficial";
const youtubeThumbnailUrl = "https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/telaport_june_dmeo.png";

function AboutUsText(): JSX.Element {
    const navigate = useNavigate();

    return (
        <Container fluid className="hero-section mt-5">
            <Row className="align-items-center padded-hero-row">
                <Col md={{ span: 8, offset: 2 }} className="hero-left d-flex flex-column align-items-center">
                    <h1 className="text-center mb-4"><strong>Trade with ANYONE. Anywhere.</strong></h1>
                    <h4 className="text-center mb-4"><strong>TELAPORT™</strong> emerged from a desire to democratize Commerce. We envisioned a world where <strong>anyone could trade anything</strong>, without the limitations imposed by financial systems. Our platform is the culmination of our passion for <strong>empowering individuals</strong> to trade freely and securely. We've dismantled the barriers of traditional trading, offering a service that's not only the <strong>most affordable</strong> in the market but also <strong>the safest.</strong></h4>
                    <h4 className="text-center">Our commitment goes beyond transactions; it's about fostering a community where trading is as much about human connection as it is about economic benefit. <strong>TELAPORT™</strong> is more than a service; <strong>it's a movement towards a more connected and equitable world of commerce, where everyone has the opportunity to participate and thrive.</strong></h4>
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" className="mt-4 mb-4"
                            onClick={() => navigate(URLS.USER_SIGNUP)}
                            disabled={Authentication.load().hasToken}
                        >
                            <strong>FREE Sign Up</strong>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

function YoutubeLink(): JSX.Element {
    // Function to handle click on the card
    const handleCardClick = () => {
        window.location.href = youtubeUrl; // Navigate to orderUrl
    };
    return (

        <Card style={{ width: '26rem' }} onClick={handleCardClick} className="cursor-pointer mx-3 mb-4 mt-4">
            <div className="mt-2 mx-3">
                <Card.Img variant="top" src={youtubeThumbnailUrl} />
            </div>
            <Card.Body>
                <Card.Title className="text-center"><strong>Learn More on Youtube!</strong></Card.Title>
            </Card.Body>
        </Card>
    )
}

export default function AboutUs() {
    return (
        <div className="App bg-light">
            <AboutUsText />
            <FAQ />
            <YoutubeLink />
            <Team />
        </div>
    );
}