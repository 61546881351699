import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import { REFRESH_TOKEN_MUTATION } from "./queries";
import { Authentication } from '../../types';

// Get a new refresh token using an internally constructed ApolloClient that
// is setup specifically for this task.
export default function getRefreshToken(link: ApolloLink): Promise<Authentication> {
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });

  const { refreshToken, hasToken } = Authentication.load();
  if (!hasToken) {
    return Promise.reject(Error("We do not have a token to attempt refresh."));
  }

  return client.mutate({
    mutation: REFRESH_TOKEN_MUTATION,
    variables: {
      refreshToken,
    },
  }).then((
    {
      data: {
        refreshToken: {
          payload: {
            username,
            userId,
            exp,
          },
          token,
          refreshToken,
          refreshExpiresIn
        }
      }
    }
  ) => Authentication.fromJWT(username, userId, token, exp, refreshToken, refreshExpiresIn));
}