import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import ApolloJWTClient from './apollo/client/ApolloJWTClient';
import { URLS } from './utils/constants';

const onAuthFailure = (reason: string) => {
  console.error(`Access failed: ${reason}`);
  const next = new URL(URLS.USER_LOGIN, window.location.toString());
  next.searchParams.set("from", window.location.toString());
  window.history.pushState(null, document.title, next);
  window.history.go();
};

const client = ApolloJWTClient(onAuthFailure);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App client={client} />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
