import { Image, Container, Row, Col } from 'react-bootstrap';
import './ContactInfo.css';



export default function ContactInfo(): JSX.Element {

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4"><strong>Contact Information</strong></h1>
      <Row className="justify-content-md-center">
        <Col md={6}>
          <div className="text-center">
            <h4>Name</h4>
            <p>Telaport, Inc.</p>
          </div>
          <div className="text-center">
            <h4>Address</h4>
            <p>
              400 N Tampa St<br />
              15th Floor<br />
              Tampa, FL 33602
            </p>
          </div>
          <div className="text-center">
            <h4>Email</h4>
            <p><a href="mailto:info@telaport.net">info@telaport.net</a></p>
          </div>
        </Col>
      </Row>
      <div className="discord-logo-container mt-3" onClick={() => window.open('https://discord.gg/jP5bkPabQz', '_blank')} >
        <Image

          src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/discord_logo.png"
          alt="Discord Logo"
          roundedCircle
          fluid
          className="discord-logo"
        />
        <p><strong>Our Discord</strong></p>
      </div>
      <div className="discord-logo-container mt-3" onClick={() => window.open('https://www.instagram.com/telaportdotnet/', '_blank')} >
        <Image

          src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/Instagram_icon.png"
          alt="Instagram Logo"
          roundedCircle
          fluid
          className="discord-logo"
        />
        <p><strong>Our Instagram</strong></p>
      </div>
    </Container>
  );
};
