import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "./Logo";
import { URLS } from "../utils/constants";
import { useMutation } from "@apollo/client";
import { USER_EMAIL_VERIFY } from "../apollo/queries/user";
import { useEffect } from "react";

const TIMEOUT_SECONDS = 3;

export default function EmailVerified(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [verifyEmail, { loading }] = useMutation(USER_EMAIL_VERIFY);
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      verifyEmail({ variables: { token } });
    }
  }, [token, verifyEmail]);

  if (!token) {
    return (
      <div className="text-center">
        <strong>A token is required to verify your email.</strong>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Verifying your email...</strong></p>
      </div>
    );
  }

  setTimeout(() => navigate(URLS.LANDING), TIMEOUT_SECONDS * 1000);
  return (
    <div className="text-center">
      <Logo />
      <p><strong>
        Thank you for verifying your email!
        In {TIMEOUT_SECONDS} seconds, you will be redirected to the landing page.
      </strong></p>
    </div>
  );
}