import { ApolloError } from "@apollo/client";
import { Button, Row, Col, Container, Table } from "react-bootstrap";
import { Order, OrderOwnerAddress, OrderPackageDetails, OrderServiceTier } from "../../apollo/queries/order";
import AddressButton from "../AddressButton";
import PackageDetailsButton from "../PackageDetailsButton";
import PayNowButton from "../PayNowButton";
import { ThumbnailImages } from "../ThumbnailImage";
import { TrackingButton } from "../TrackingButton";
import Modal, { useModalProperties } from "../Modal";
import { LabelButton as ShowLabelButton } from "../LabelButton";
import ReactDOM from 'react-dom/client';
import ReactBarcode from "react-jsbarcode";

type BarcodeProperties = {
  domWindow: Window | null;
  value: string;
}

function Barcode({ domWindow, value }: BarcodeProperties): JSX.Element {
  return (
    <Container>
      <Row className="text-center">
        <Col>
          <ReactBarcode value={value} options={{ format: "code128" }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <strong>Include this document inside your package.</strong>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={() => domWindow?.print()}>Print</Button>
        </Col>
      </Row>
    </Container>
  );
}

type LabelButtonProperties = {
  order: Order;
};

type LabelPanelProperties = {
  order: Order;
  onClose: () => void;
}

function LabelPanel({ order, onClose }: LabelPanelProperties): JSX.Element {
  const barcode = (w: Window | null) => <Barcode value={order.slug} domWindow={w} />;
  return (
    <Container>
      <Row className="my-2 text-center">
        <Col>
          <Button onClick={() => {
            const w = window.open("", "_blank");
            const root = ReactDOM.createRoot(
              w?.document.documentElement as HTMLElement
            );
            root.render(barcode(w));
          }}
          >
            Print Instructions
          </Button>
        </Col>
      </Row>
      <Row className="my-2 text-center">
        <Col>
          <ShowLabelButton
            text="Print Label"
            imageBase64={order.orderlabel?.inboundImageBase64 || ""}
          />
        </Col>
      </Row>
      <Row className="my-2 text-center">
        <Col>
          <TrackingButton
            trackingNumber={order.orderlabel?.inboundTrackingNumber || ""}
          >
            Track
          </TrackingButton>
        </Col>
      </Row>
      <Row className="my-2 text-center">
        <Col>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

function LabelButton({ order }: LabelButtonProperties): JSX.Element {
  const [labelModalProperties, setLabelModalProperties] = useModalProperties({
    type: "info",
    title: "Shipping Information",
    body: <LabelPanel
      order={order}
      onClose={() => setLabelModalProperties(({ show: false }))}
    />
  });

  return (<>
    <Modal {...labelModalProperties} />
    <Button className="mx-1 mt-3" onClick={() => setLabelModalProperties(({ show: true }))}>
      Shipping Info
    </Button>
  </>);
}

export type OrderStatusPanelProperties = {
  order: Order;
  onSetAddress: (address: OrderOwnerAddress) => void;
  onSetPackage: (dimensions: OrderPackageDetails) => void;
  onSetServiceTier: (tier: OrderServiceTier) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
  onNavigateToOrder: (orderId: string) => void;
  onApprove: () => void;
  onReject: () => void;
}

type OrderStatusPanelButtonsProperties = {
  order: Order;
  onError: (error: string | ApolloError | JSX.Element) => void;
  onSetAddress: (address: OrderOwnerAddress) => void;
  onSetPackage: (dimensions: OrderPackageDetails) => void;
  onSetServiceTier: (tier: OrderServiceTier) => void;
  onNavigateToOrder: (orderId: string) => void;
  onApprove: () => void;
  onReject: () => void;
}

function OrderStatusPanelButtons(
  {
    order,
    onError,
    onSetAddress,
    onSetPackage,
    onSetServiceTier,
    onNavigateToOrder,
    onApprove,
    onReject,
  }: OrderStatusPanelButtonsProperties): JSX.Element {
  const children: JSX.Element[] = [];
  const associatedOrder = order.associatedOrder;

  if (associatedOrder === undefined) {
    return (<></>);
  }

  const orderCanBeApproved = (order: Order, canApprove: boolean | undefined) => (
    Boolean(
      canApprove &&
      order.received &&
      !order.approved &&
      !order.rejected
    )
  );

  children.push(
    <Button
      key="other-order"
      className="mx-1 mt-3"
      variant={orderCanBeApproved(associatedOrder, order.owner?.isMe) ? "danger" : "secondary"}
      onClick={() => onNavigateToOrder(associatedOrder.slug)}
    >
      {orderCanBeApproved(associatedOrder, order.owner?.isMe) ? "Approve Order" : "Other Order"}
    </Button>
  );

  if (orderCanBeApproved(order, associatedOrder.owner?.isMe)) {
    children.push(
      <Button
        key="approve-order"
        className="mx-1 mt-3"
        onClick={onApprove}
      >
        Approve
      </Button>,
      <Button
        key="reject-order"
        className="mx-1 mt-3"
        variant="danger"
        onClick={onReject}
      >
        Reject
      </Button>
    )
  }

  if (order.owner?.isMe && !Boolean(order.warehousePaymentStatus?.paid)) {
    children.push(
      <AddressButton
        key="add-address"
        order={order}
        refetchQueries="active"
        onSetAddress={onSetAddress}
        onError={onError}
      />
    );

    children.push(
      <PackageDetailsButton
        key="add-package"
        order={order}
        refetchQueries="active"
        onSetPackage={onSetPackage}
        onError={onError}
      />
    )
  }

  if (order.owner?.isMe) {
    if (!Boolean(order.warehousePaymentStatus?.paid)) {
      if (!order.editable && order.address && order.package) {
        children.push(
          <PayNowButton
            key="pay-warehouse-shipping"
            buttonTitle="Pay Shipping + Service"
            order={order}
            shippingTo="WAREHOUSE"
            refetchQueries="active"
            onError={onError}
            onSetServiceTier={onSetServiceTier}
          />
        );
      }
    } else if (!order.received) {
      children.push(
        <LabelButton
          key="view-label"
          order={order}
        />
      );
    }

    if (!Boolean(order.destinationPaymentStatus?.paid)) {
      if ((order.approved && order.associatedOrder?.approved) || order.rejected) {
        const title = order.approved ? "Pay to Receive Package" : "Pay to Return Package";
        children.push(
          <PayNowButton
            key="pay-destination-shipping"
            buttonTitle={title}
            order={order}
            shippingTo="DESTINATION"
            refetchQueries="active"
            onError={onError}
            onSetServiceTier={onSetServiceTier}
          />
        );
      }
    } else {
      children.push(
        <TrackingButton
          key="track-destination-shipping"
          className="mx-1 mt-3"
          trackingNumber={order.orderlabel?.outboundTrackingNumber || ""}
        >
          Track
        </TrackingButton>
      );
    }
  }

  return (<>{children}</>);
}

export default function OrderStatusPanel(
  {
    order,
    onSetAddress,
    onSetPackage,
    onSetServiceTier,
    onError,
    onNavigateToOrder,
    onApprove,
    onReject,
  }: OrderStatusPanelProperties
): JSX.Element {
  if (order.associatedOrder?.associatedOrder?.slug !== order.slug) {
    return (<></>);
  }

  const lhs = order.owner?.isMe ? order : order.associatedOrder;
  const rhs = order.owner?.isMe ? order.associatedOrder : order;

  const warehouseFeedback = (<>
    <Row className="mt-3">
      <Col className="h1">Warehouse Feedback</Col>
    </Row>

    {
      order.warehouseImageUrls &&
      <Row className="mt-3">
        <Col>
          <ThumbnailImages imageUrls={order.warehouseImageUrls || []} />
        </Col>
      </Row>
    }

    <Row className="mt-3">
      <Col><strong>Description</strong></Col>
    </Row>
    {
      <Row className="mt-3">
        <Col>
          <div>
            {
              order.warehouseDescription || "Received"
            }
          </div>
        </Col>
      </Row>
    }
  </>);

  return (<>
    <Container className="square border border-3 mb-3">
      <Row>
        <Col>
          <Container>
            <Row className="mb-3 text-center">
              <Col>
                <OrderStatusPanelButtons
                  order={order}
                  onSetAddress={onSetAddress}
                  onSetPackage={onSetPackage}
                  onSetServiceTier={onSetServiceTier}
                  onError={onError}
                  onNavigateToOrder={onNavigateToOrder}
                  onApprove={onApprove}
                  onReject={onReject}
                />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="h1">
          Your Order Details
        </Col>
        <Col className="h1">
          Counterparty Details
        </Col>
      </Row>
      <Row className="mt=3">
        <Col>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td><strong>Title</strong></td>
                <td>{lhs.title}</td>
              </tr>
              <tr>
                <td><strong>Id</strong></td>
                <td>{lhs.slug}</td>
              </tr>
              <tr>
                <td><strong>Status</strong></td>
                <td>{lhs.status?.name}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td><strong>Title</strong></td>
                <td>{rhs.title}</td>
              </tr>
              <tr>
                <td><strong>Id</strong></td>
                <td>{rhs.slug}</td>
              </tr>
              <tr>
                <td><strong>Status</strong></td>
                <td>{rhs.status?.name}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {order.associatedOrder.owner?.isMe && order.received && warehouseFeedback}
    </Container>
  </>
  );
}