export default function checkPasswordComplexity(password: string, setError: (error: string) => void) {
  const symbol = /\p{P}|\p{S}/gu;
  const lower = /\p{Lower}/gu;
  const upper = /\p{Upper}/gu;
  const number = /\p{Number}/gu;

  if (!(upper.test(password) && lower.test(password) && number.test(password) && symbol.test(password))) {
    setError("Passwords must contain 1 upper-case, 1 lower-case, 1 number, and 1 special character.");
    return;
  }
  if (password.length < 8) {
    setError("Password must be at least 8 characters long.");
    return;
  }
  setError("");
}
