import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { ApolloError, RefetchQueriesInclude, useMutation } from "@apollo/client";
import { DEFAULT_ORDER_PACKAGE_DETAILS, Order, ORDER_SET_PACKAGE_DETAILS, OrderPackageDetails } from "../apollo/queries/order";
import Modal, { useModalProperties } from "../components/Modal";
import PackageDetailsForm from "../forms/PackageDetailsForm";

type PackageDetailsButtonProperties = {
  order: Order;
  refetchQueries: RefetchQueriesInclude;
  onSetPackage: (dimweight: OrderPackageDetails) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
}

export default function PackageDetailsButton({ order, refetchQueries, onSetPackage, onError }: PackageDetailsButtonProperties): JSX.Element {
  const [setPackage] = useMutation(ORDER_SET_PACKAGE_DETAILS, { refetchQueries });
  const title = order.package ? "Update Package Details" : "Add Package Details";
  const [packageModalProperties, setPackageModalProperties] = useModalProperties({
    type: "info",
    title,
  });

  useEffect(() => {
    const formOnClose = () => setPackageModalProperties(({ show: false }));
    setPackageModalProperties(({
      body: <PackageDetailsForm
        package={order.package || DEFAULT_ORDER_PACKAGE_DETAILS}
        onSetPackageDetails={packageDetails =>
          setPackage({
            variables: {
              slug: order.slug,
              lengthInches: packageDetails.lengthInches,
              heightInches: packageDetails.heightInches,
              widthInches: packageDetails.widthInches,
              pounds: packageDetails.pounds,
              renderAsCentimeters: packageDetails.renderAsCentimeters,
              renderAsKilograms: packageDetails.renderAsKilograms,
              monetaryValueCurrency: packageDetails.monetaryValueCurrency,
              monetaryValue: Number(packageDetails.monetaryValue),
              forReturn: false,
            }
          }).then(({ data: { orderSetPackageDetails: { order } } }) => onSetPackage && onSetPackage(order.package))
            .then(formOnClose)
            .catch(onError)
        }
        onClose={formOnClose} />
    }))
  }, [order, onError, onSetPackage, setPackage, setPackageModalProperties]);

  const onClick = () => setPackageModalProperties(({ show: true }));

  if (!Boolean(order.warehousePaymentStatus?.paid)) {
    return (
      <>
        <Modal {...packageModalProperties} />
        <Button
          className="mx-1 mt-3"
          variant={order.package ? "secondary" : "primary"}
          onClick={onClick}
        >
          {title}
        </Button>
      </>
    )
  }

  return (<></>);
}