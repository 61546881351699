import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  from,
  fromPromise,
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { Authentication } from '../../types';
import getRefreshToken from '../jwt/getRefreshToken';
import { StatusCodes } from 'http-status-codes';

const CSRF_DISABLE: boolean = true;
let CSRF_TOKEN: string;

const APOLLO_SERVER_URL = window.location.hostname === "localhost" ?
  "http://localhost:8001" :
  "https://api.prod.telaport.net";


const HTTP_API_LINK = new HttpLink({
  uri: `${APOLLO_SERVER_URL}/telaport/api/`,
  credentials: "include",
});

const AUTHENTICATION_CHECK_EVERY_X_SECONDS = 10;
let AUTHENTICATION_UPDATER: NodeJS.Timer;

const getAuthMiddleware = (getCsrfToken: () => string) => new ApolloLink(
  (operation: Operation, forward: NextLink): Observable<FetchResult> => {
    // Add the CSRF token to the header.
    const csrfToken = getCsrfToken();
    if (csrfToken) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "X-CSRFToken": csrfToken,
        }
      }));
    }

    // Add the JWT token to the header.
    const auth = Authentication.load();
    if (auth.hasToken) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          ...auth.headers,
        }
      }));
    }
    return forward(operation);
  });

const onErrorUpdateAuthentication = (updateAuthentication: () => Promise<Authentication>, onAuthFailure: (reason: any) => void) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let { message, locations, path } of graphQLErrors) {
        if (new RegExp("PERMISSION", "i").test(message)) {
          console.debug("UNAUTHENTICATED; trying refresh token");
          return fromPromise(
            updateAuthentication()
              .then(authentication =>
                authentication
                  .save()
                  .token
              )
              .catch((reason) => {
                onAuthFailure(`Failed to refresh token: ${reason}`);
              })
          )
            .filter(token => Boolean(token))
            .flatMap(() => forward(operation));
        } else {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        }
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

const fetchCsrfToken = (): string => {
  if (CSRF_DISABLE) {
    return "";
  }

  if (CSRF_TOKEN) {
    return CSRF_TOKEN;
  }

  const request = new XMLHttpRequest();
  request.withCredentials = true;  // get cookies
  request.open("GET", `${APOLLO_SERVER_URL}/csrf`, false);
  request.send(null);
  if (request.status === StatusCodes.OK) {
    const data = JSON.parse(request.responseText);
    CSRF_TOKEN = data.token;
    return CSRF_TOKEN;
  }
  return "";
}

// Build and return an ApolloClient with JWT capabilities.
export default function ApolloJWTClient(onAuthFailure: (reason: any) => void) {
  console.debug("Apollo client using URI", HTTP_API_LINK.options.uri);

  const updateAuthentication = () => {
    const auth = Authentication.load();
    if (auth.hasToken && auth.expired) {
      getRefreshToken(HTTP_API_LINK).then(authentication => authentication.save());
    }
  };

  if (!Boolean(AUTHENTICATION_UPDATER)) {
    console.debug("Installing an authentication token watcher.");
    updateAuthentication(); // check immediately before setting timer
    AUTHENTICATION_UPDATER = setInterval(
      updateAuthentication,
      AUTHENTICATION_CHECK_EVERY_X_SECONDS * 1000,
    );
  }

  const link = from([getAuthMiddleware(fetchCsrfToken), HTTP_API_LINK]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      onErrorUpdateAuthentication(() => getRefreshToken(link), onAuthFailure),
      link,
    ]),
  });
}
