import React, { useEffect, useState } from "react";
import { useS3Uploader } from "../apollo";
import { Form, FormControlProps } from "react-bootstrap";
import { ErrorEventHandler } from "../types";
import { ImageSize, useImage } from "../image";
import { NotAnImageError } from "../image/errors";
import { changeFilenameExtension, filenameExtension } from "../image/utilities";

export type ImageUploadResult = {
  imageUrl: string;
  thumbnailUrl: string;
}

export type ImageUploadOptions = {
  maxImageBytes?: number,
  maxThumbnailBytes?: number
}

export type FilePickerProperties = Omit<FormControlProps, "onChange" | "onError"> & {
  autoClear?: boolean;
  count?: number;
  onChange: (files: File[]) => void;
  onError: ErrorEventHandler;
}

export function FilePickerControl(
  {
    autoClear,
    className,
    count,
    onChange,
    onError,
    ...props
  }: FilePickerProperties
): JSX.Element {
  const maxCount = count || Number.MAX_VALUE;

  const onFilesChange = (files: File[]) => {
    if (files.length > maxCount) {
      onError(`A maximum of ${maxCount} images may be chosen.`);
      return;
    }
    onChange(files);
  };

  return (
    <Form.Control
      type="file"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onFilesChange(
          e.target.files ? Array.from(e.target.files) : []
        );
        if (autoClear) {
          e.target.value = "";
        }
      }}
      {...props}
    />
  );
}

export type UseFilePickerProperties = {
  urls: string[];
  onChange: (urls: string[]) => void;
  onError: ErrorEventHandler;
}

export type FilePicker = {
  loading: boolean;
  upload: (classification: string, maxBytes?: number) => Promise<string[]>;
  uploadImage: (classification: string, thumbnailSize: ImageSize, options?: ImageUploadOptions) => Promise<ImageUploadResult[]>;
  revokeIfBlob: (url: string) => void;
  appendFiles: (files: File[]) => void;
  clearFiles: () => void;
}

export const filenameAsThumbnail = (filename: string): string =>
  filename.startsWith("blob:")
    ? filename
    : changeFilenameExtension(filename, `thumbnail.${filenameExtension(filename)}`);

export default function useFilePicker(
  {
    urls,
    onChange,
    onError,
  }: UseFilePickerProperties
): FilePicker {
  const image = useImage();
  const [uploading, setUploading] = useState(false);
  const [blobs, setBlobs] = useState<Record<string, File>>({});
  const [upload, { loading }] = useS3Uploader({ onError });

  const uploadImage = async (
    classification: string,
    file: File,
    thumbnailSize: ImageSize,
    {
      maxImageBytes = 0,
      maxThumbnailBytes = 0,
    }: ImageUploadOptions,
  ): Promise<ImageUploadResult> => {
    thumbnailSize = await image.resize(file, thumbnailSize);  // resize keeps aspect ratios
    if (!file.type.startsWith("image/")) {
      throw new NotAnImageError(file.type);
    }

    setUploading(true);
    try {
      file = await image.convert(file, { type: "image/jpeg" });
      if (maxImageBytes > 0) {
        file = await image.compress(file, { maxBytes: maxImageBytes });
      }
      const imageUrl = await upload(classification, file);

      const thumbnail = new File(
        [file],
        filenameAsThumbnail(file.name),
        { type: file.type },
      );
      file = await image.convert(thumbnail, { type: "image/jpeg", size: thumbnailSize });
      if (maxThumbnailBytes > 0) {
        file = await image.compress(file, { maxBytes: maxThumbnailBytes });
      }
      const thumbnailUrl = await upload(classification, file);

      return {
        imageUrl,
        thumbnailUrl,
      }
    } finally {
      setUploading(false);
    }
  }

  useEffect(() => {
    return () => {
      for (const url in blobs) {
        URL.revokeObjectURL(url);
      }
    }
  }, [blobs]);

  const revokeIfBlob = (url: string) => {
    if (url in blobs) {
      URL.revokeObjectURL(url);
      const { [url]: _, ...remaining } = blobs;
      setBlobs(remaining);
    }
  }

  return {
    loading: loading || uploading,
    appendFiles: (files: File[]) => onChange(urls.concat(
      files.map(file => {
        const url = URL.createObjectURL(file);
        setBlobs(prev => ({ ...prev, [url]: file }));
        return url;
      })
    )),
    clearFiles: () => {
      Object.keys(blobs).forEach(url => URL.revokeObjectURL(url))
      setBlobs({});
    },
    upload: (classification: string, maxBytes?: number) =>
      Promise.all((urls || []).map(async url => {
        if (url in blobs) {
          const uploadedUrl = await upload(classification, blobs[url], maxBytes);
          revokeIfBlob(url);
          return uploadedUrl;
        }
        return Promise.resolve(url);
      })),
    uploadImage: (
      classification: string,
      thumbnailSize: ImageSize,
      options?: ImageUploadOptions,
    ) =>
      Promise.all((urls || []).map(async url => {
        if (url in blobs) {
          return await uploadImage(classification, blobs[url], thumbnailSize, options || {});
        }
        return Promise.resolve({
          imageUrl: url,
          thumbnailUrl: filenameAsThumbnail(url),
        });
      })),
    revokeIfBlob,
  };
}
