import { Button } from "react-bootstrap";

export type LabelButtonProperties = {
  text: string;
  imageBase64: string;
  onClick?: () => void;
}

export function LabelButton({ text, imageBase64, onClick }: LabelButtonProperties): JSX.Element {
  return (<Button onClick={() => {
    const image = new Image();
    image.src = imageBase64;
    image.addEventListener("load", () => { w?.print(); w?.close(); });
    const w = window.open("", "_blank");
    w?.document.write(image.outerHTML);
    onClick && onClick();
  }}>
    {text}
  </Button>);
}