import './TelaportBanner.css'

function TelaportBanner() {
  return (
    <div className="banner">
    </div>
  );
}

export default TelaportBanner;
