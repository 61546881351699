import { ORDER_COMPLETE, ORDER_LABELS_FOR_DESTINATION, Order, OrderLabel, OrderLabelForDestinationResult } from "../../apollo/queries/order";
import { ApolloError, useMutation, useQuery } from "@apollo/client";
import Logo from "../Logo";
import { useState } from "react";
import { DEFAULT_PAGE_INFO, PageInfo } from "../../apollo/queries/pageinfo";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Modal, { useModalProperties } from "../Modal";
import { LabelButton } from "../LabelButton";
import PaginationNavigator, { DEFAULT_ITEMS_PER_PAGE_OPTIONS } from "../PaginationNavigator";
import { ArrowClockwise } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

export type WarehouseShippingformProperies = {
  onError: (error: string | ApolloError) => void;
}

type LabelDetailProperties = {
  label: OrderLabel;
  completed: boolean;
  onClose: () => void;
  onComplete: () => void;
}

function LabelDetail({ label, completed, onClose, onComplete }: LabelDetailProperties): JSX.Element {
  const [printShown, setPrintShown] = useState(false);

  return (<Container>
    <Row>
      <Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td><strong>Order Id</strong></td>
              <td><strong>Shelf</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{label.order?.slug}</td>
              <td>{label.order?.warehouseShelf}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row>
      <Col>
        <LabelButton
          text="Print Label"
          imageBase64={label.outboundImageBase64 || ""}
          onClick={() => setPrintShown(true)}
        />
      </Col>
      <Col>
        <Button
          variant={completed ? "secondary" : "primary"}
          disabled={!printShown || completed}
          onClick={() => {
            onComplete();
            completed = true;
          }}
        >
          Complete
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Button
          className="w-100 mt-3"
          variant="secondary"
          onClick={onClose}>
          Close
        </Button>
      </Col>
    </Row>
  </Container>);
}

export default function WarehouseShippingform({ onError }: WarehouseShippingformProperies): JSX.Element {
  const navigate = useNavigate();
  const [labels, setLabels] = useState<OrderLabel[]>([]);
  const [pageInfo, setPageInfo] = useState<PageInfo>(DEFAULT_PAGE_INFO);
  const [pageLimit, setPageLimit] = useState(DEFAULT_ITEMS_PER_PAGE_OPTIONS[1]);
  const [completedSlugs, setCompletedSlugs] = useState<Set<string>>(new Set<string>());

  const onOrderLabelsFetched = (
    { orderLabelsForDestination: { edges, pageInfo } }: OrderLabelForDestinationResult,
    override?: { hasPreviousPage?: boolean, hasNextPage?: boolean },

  ) => {
    setLabels(edges.map(edge => ({ ...edge.node })));
    if (override) {
      const { hasPreviousPage, hasNextPage } = override;
      if (hasPreviousPage !== undefined) {
        pageInfo.hasPreviousPage = hasPreviousPage;
      }
      if (hasNextPage !== undefined) {
        pageInfo.hasNextPage = hasNextPage;
      }
    }
    setPageInfo(pageInfo);
    setCompletedSlugs(new Set<string>());
  };

  const { loading: fetchingLabels, fetchMore: fetchMoreLabels, refetch: refetchLabels } = useQuery<OrderLabelForDestinationResult>(
    ORDER_LABELS_FOR_DESTINATION,
    {
      variables: {
        first: pageLimit,
      },
      fetchPolicy: "network-only",
      onCompleted: onOrderLabelsFetched,
      onError,
    }
  );

  const [completeOrder, { loading: completingOrder }] = useMutation<Order>(
    ORDER_COMPLETE,
    {
      onError,
    });

  const [modalProperties, setModalProperties] = useModalProperties({
    title: "Item",
    type: "info",
  });

  const loading = fetchingLabels || completingOrder;

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Fetching all destination labels on uncompleted orders...</strong></p>
      </div>
    );
  }

  if (labels.length === 0) {
    return (<div>
      <Button variant="secondary"
        className="mx-3"
        disabled={loading}
        onClick={() => navigate(0)}
      >
        <ArrowClockwise />
      </Button>
      There is nothing to ship. Please check back later.
    </div>);
  }

  return (<Container>
    <Row>
      <Col>
        <Modal {...modalProperties} />
      </Col>
    </Row>
    <Row>
      <Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td><strong>Order Id</strong></td>
              <td><strong>Shelf</strong></td>
              <td><strong>Action</strong></td>
            </tr>
          </thead>
          <tbody>
            {labels.map((label, index) => {
              return (
                <tr className="align-middle" key={`label-${index}`}>
                  <td>{label.order?.slug}</td>
                  <td>{label.order?.warehouseShelf}</td>
                  <td>
                    <Button
                      variant={completedSlugs.has(label.order?.slug || "") ? "secondary" : "primary"}
                      onClick={() => setModalProperties(
                        ({
                          show: true,
                          body: <LabelDetail
                            label={label}
                            completed={completedSlugs.has(label.order?.slug || "")}
                            onClose={() => setModalProperties(({ show: false }))}
                            onComplete={() => {
                              completeOrder({ variables: { slug: label.order?.slug } }).then(
                                () => {
                                  setCompletedSlugs(prev => new Set(prev.add(label.order?.slug || "")));
                                  setModalProperties(({ show: false }));
                                }
                              )
                            }}
                          />
                        })
                      )}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Button variant="secondary"
          disabled={!pageInfo.hasPreviousPage}
          onClick={() => fetchMoreLabels({
            variables: {
              first: undefined,
              last: pageLimit,
              before: pageInfo.startCursor,
            }
          }).then(({ data }) => onOrderLabelsFetched(data, { hasNextPage: true }))}
        >
          {"<<"}
        </Button>
        <Button variant="secondary"
          className="mx-2"
          disabled={!pageInfo.hasNextPage}
          onClick={() => fetchMoreLabels({
            variables: {
              after: pageInfo.endCursor,
            }
          }).then(({ data }) => onOrderLabelsFetched(data, { hasPreviousPage: true }))}
        >
          {">>"}
        </Button>
        <Button variant="secondary"
          className="mx-2"
          onClick={() => refetchLabels().then(({ data }) => onOrderLabelsFetched(data))}
        >
          <ArrowClockwise />
        </Button>
      </Col>
      <Col>
        <PaginationNavigator
          pageInfo={pageInfo}
          pageLimit={pageLimit}
          fetchMore={fetchMoreLabels}
          onChangePageLimit={setPageLimit}
          onPreviousPage={data => onOrderLabelsFetched(data, { hasNextPage: true })}
          onNextPage={data => onOrderLabelsFetched(data, { hasPreviousPage: true })}
        />
      </Col>
    </Row>
  </Container>);
}
