import { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import SpinnerButton from "../components/SpinnerButton";
import { ApolloError, RefetchQueriesInclude, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Order, ORDER_CHECKOUT_CREATE, ORDER_SERVICE_TIERS, OrderServiceTier, OrderShippingRate, ORDER_SHIPPING_RATES, OrderShippingRatesResult, ORDER_SET_SERVICE_TIER, ORDER_PAYMENT_STATUS, OrderPaymentStatusResult } from "../apollo/queries/order";
import Logo from "../components/Logo";
import { UserResult, USER_GET_ME_QUERY, UserDiscount } from "../apollo/queries/user";

type PaymentFormProperties = {
  order: Order;
  shippingTo: "WAREHOUSE" | "DESTINATION";
  refetchQueries: RefetchQueriesInclude;
  onSetServiceTier: (tier: OrderServiceTier) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
  onClose: () => void;
}

export default function PaymentForm({ order, shippingTo, refetchQueries, onError, onSetServiceTier, onClose }: PaymentFormProperties): JSX.Element {
  const [serviceTiers, setServiceTiers] = useState<OrderServiceTier[]>([]);
  const [serviceTier, setServiceTier] = useState<OrderServiceTier>();
  const [shippingRates, setShippingRates] = useState<OrderShippingRate[]>([]);
  const [availableDiscounts, setAvailableDiscounts] = useState<UserDiscount[]>([]);
  const [discount, setDiscount] = useState<UserDiscount>();
  const [friendsAndFamily, setFriendsAndFamily] = useState(false);
  const [shippingService, setShippingService] = useState("");
  const { loading: loadingServiceTiers } = useQuery(ORDER_SERVICE_TIERS, {
    onCompleted: ({ serviceTiers: { edges } }) => setServiceTiers(edges.map((edge: any) => edge.node)),
  });
  const { loading: loadingShippingRates } = useQuery<OrderShippingRatesResult>(ORDER_SHIPPING_RATES, {
    variables: {
      slug: order.slug,
      shippingTo
    },
    fetchPolicy: "network-only",
    onCompleted: (({ orderShippingRates }) => setShippingRates(orderShippingRates)),
    onError
  });
  const [createCheckout, { loading: creatingCheckout }] = useMutation(ORDER_CHECKOUT_CREATE, { refetchQueries, onError });
  const [orderUpdateServiceTier, { loading: updatingServiceTier }] = useMutation(ORDER_SET_SERVICE_TIER, { refetchQueries, onError });
  const [orderPaymentStatus, { data: paymentStatus, loading: loadingPaymentStatus, stopPolling: stopStatusPolling }] = useLazyQuery<OrderPaymentStatusResult>(ORDER_PAYMENT_STATUS, { onError });
  const { loading: loadingUser } = useQuery<UserResult>(USER_GET_ME_QUERY, {
    onCompleted: ({ me }) => {
      setAvailableDiscounts(me.discounts?.sort((lhs, rhs) => {
        if (lhs.value > rhs.value) {
          return -1;
        }
        if (lhs.value < rhs.value) {
          return 1;
        }
        if (lhs.name < rhs.name) {
          return -1;
        }
        if (lhs.name > rhs.name) {
          return 1;
        }
        return 0;
      }) || []);
      setFriendsAndFamily(me.friendsAndFamily || false);
    },
    onError,
  });
  const loading = loadingServiceTiers || loadingShippingRates || loadingUser;

  const checkout = useCallback((slug: string) => {
    const successUrl = new URL(window.location.toString());
    const cancelUrl = new URL(window.location.toString());
    successUrl.searchParams.set("payment", "succeeded");
    cancelUrl.searchParams.set("payment", "canceled");
    return createCheckout({
      variables: {
        slug,
        shippingTo,
        shippingService,
        discountCode: discount?.slug,
        successUrl,
        cancelUrl,
      }
    }).then(({ data: { checkoutCreate: { statusSlug } } }) =>
      orderPaymentStatus({ variables: { slug: statusSlug }, pollInterval: 1000 })
    )
  }, [shippingTo, shippingService, discount, createCheckout, orderPaymentStatus]);

  useEffect(() => {
    if (paymentStatus && paymentStatus.orderPaymentStatus.paymentUrl) {
      stopStatusPolling();
      window.location.href = paymentStatus.orderPaymentStatus.paymentUrl;
    }
  }, [paymentStatus, stopStatusPolling])

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Fetching all your service and shipping options...</strong></p>
      </div>
    );
  }

  const disabledForm = (!serviceTier && shippingTo === "WAREHOUSE")
    || !shippingService
    || creatingCheckout
    || updatingServiceTier
    || loadingPaymentStatus;

  return (
    <Form onSubmit={e => {
      e.preventDefault();
      if ((serviceTier || shippingTo !== "WAREHOUSE") && shippingService) {
        if (serviceTier) {
          onSetServiceTier(serviceTier);
          orderUpdateServiceTier(
            {
              variables: {
                slug: order.slug,
                serviceTier: serviceTier.slug,
              }
            }
          ).then(() => { checkout(order.slug) });
        } else {
          checkout(order.slug);
        }
      }
    }}>
      {
        shippingTo === "WAREHOUSE" &&
        <Form.Group>
          <Form.Label><strong>Service Tier</strong></Form.Label>
          {serviceTiers.map((tier, idx) => {
            const price = Number(tier.price) * (friendsAndFamily ? 0.75 : 1.0);
            return <Form.Check
              className="mx-1"
              key={`tier-${idx}`}
              type="radio"
              label={`${tier.name} ($${price.toFixed(2)})`}
              checked={serviceTier?.slug === tier.slug}
              onChange={() => setServiceTier(tier)}
            />
          })}
        </Form.Group>
      }

      <Form.Group>
        <Form.Label><strong>Shipping Service</strong></Form.Label>
        {shippingRates.map((rate, idx) =>
          <Form.Check
            className="mx-1"
            key={`rate-${idx}`}
            type="radio"
            label={`${rate.estimatedTimeInTransit.serviceDescription} ($${Number(rate.cost).toFixed(2)})`}
            checked={rate.shippingService === shippingService}
            onChange={() => setShippingService(rate.shippingService)}
          />
        )}
      </Form.Group>

      {
        availableDiscounts.length > 0 &&
        <Form.Group>
          <Form.Label><strong>Service Discount Code</strong></Form.Label>
          <Form.Select
            value={discount?.slug}
            onChange={e => setDiscount(availableDiscounts.filter(ud => ud.slug === e.target.value)[0])}
          >
            <option value="">Select Service Discount</option>
            {
              availableDiscounts.map((entry, idx) =>
                <option
                  key={`option-${idx}`}
                  value={entry.slug}
                >
                  {entry.name} ({entry.value * 100}%)
                </option>
              )
            }
          </Form.Select>
        </Form.Group>
      }

      <div className="d-flex justify-content-end">
        <SpinnerButton
          type="submit"
          className="mt-3 mb-4 mx-1"
          spin={creatingCheckout}
          disabled={disabledForm}
        >
          Pay
        </SpinnerButton>

        <SpinnerButton
          variant="secondary"
          className="mt-3 mb-4 mx-1"
          type="button"
          spin={creatingCheckout}
          onClick={onClose}
        >
          Close
        </SpinnerButton>
      </div>
    </Form>
  );
}