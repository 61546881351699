import { gql } from "@apollo/client";


export const DEFAULT_PAGE_INFO: PageInfo = {
  startCursor: "",
  endCursor: "",
  hasNextPage: false,
  hasPreviousPage: false,
}

export type PageInfo = {
  startCursor: string;
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export const PAGE_INFO_FRAGMENT = gql`
fragment PageInfo on PageInfo {
  startCursor
  endCursor
  hasNextPage
  hasPreviousPage
}
`;
