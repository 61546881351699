import React from 'react';
import './SplitLandingHero.css'
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { URLS } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Authentication } from '../types';

const carouselStyle = {
    paddingTop: '50px', // Adjust the top padding for the carousel
};

const SplitLandingHeroFriendly = () => {
    const navigate = useNavigate();

    return (
        <Container fluid className="hero-section mb-4">
            <Row className="align-items-center padded-hero-row">
                {/* <Col md={6} className="hero-right">
                    <img
                        src="/static/images/logo_with_bg.png"
                        alt="Telaport Logo with Yellow Background"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </Col> */}
                <Col lg={6} className="hero-carousel" style={carouselStyle}>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block mx-auto img-fluid"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/split-header-carousel_3_small.png"
                                alt="A Pokemon card and open package in Background. Telaport logo in the front. Trade cards. collectibles. anything. with anyone. Telaport.net."
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block mx-auto img-fluid"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/split-header-carousel_2_small.png"
                                alt="Shows the telaport logo with the Collect-A-Con Logo showing the date of May 25 to May 26, and below it states that we are going to be to the right of the performance stage doing giveaways for free."
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block mx-auto img-fluid"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/split-header-carousel_1_small.png"
                                alt="Shows a phone with a listing on someones phone showing a pokemon box for sale. Shows how you can share the link with other people on various websites and groups. Another smaller hand is holding and looking at the listing of he pokemon box that was shared."
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block mx-auto img-fluid"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/split-header-carousel_4_small.png"
                                alt="A purse, sweater, and open box in Background. Telaport logo in the front. Trade clothes. collectibles. anything. with anyone. Telaport.net."
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>

                <Col md={6} className="mb-4 mt-4 hero-left d-flex flex-column align-items-center">
                    <h1 className="text-center mb-4"><strong>No More % Based Fees</strong></h1>
                    <h1 className="text-center mb-4"><strong>Only at TELAPORT!</strong></h1>
                    <h4 className="text-center mb-4"><strong>TELAPORT</strong> is the most affordable eCommerce Marketplace! We got rid of those nasty % based fees other giant platforms like eBay and Amazon use.</h4>
                    <h4 className="text-center"><strong>TELAPORT</strong> is the ONLY Seller Friendly eCommerce Marketplace! Yes! We solve the common seller issues associated with platforms like eBay and Mercari!</h4>
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" className="mt-4"
                            onClick={() => navigate(URLS.USER_SIGNUP)}
                            hidden={Authentication.load().hasToken}
                        >
                            <strong>FREE Sign Up</strong>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SplitLandingHeroFriendly;