import { useMutation } from "@apollo/client";
import { Order, ORDER_REPORT_LISTING, ORDERS } from "../../apollo/queries/order";
import ReportModal from "../ReportModal";
import { InfoEventHandler, ErrorEventHandler } from "../../types";
import { Container, Row, Col } from "react-bootstrap";

export type OrderModerationReportProperties = {
  order: Order;
  show: boolean;
  onClose: () => void;
  onInfo: InfoEventHandler;
  onError: ErrorEventHandler;
}

export default function OrderModerationReport(
  {
    order,
    show,
    onClose: setClose,
    onError,
    onInfo: setInfo,
  }: OrderModerationReportProperties
): JSX.Element {
  const [sendReport, { loading }] = useMutation(ORDER_REPORT_LISTING, {
    refetchQueries: [ORDERS],
    onError,
  });
  return (
    <ReportModal
      title="Report Listing"
      disabled={loading}
      show={show}
      onError={onError}
      onClickClose={() => setClose()}
      onClickReport={(_, report) => sendReport({
        variables: {
          slug: order.slug,
          category: report.category.slug,
          comment: report.comment,
        }
      }).then(() => {
        setClose();
        setInfo("Report Submitted",
          <Container>
            <Row>
              <Col>
                Thank you for submitting a report for our customer support team to reveiw.
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <strong>Category</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {report.category.name}
              </Col>
            </Row>
            {
              report.comment &&
              <>
                <Row className="mt-1">
                  <Col>
                    <strong>Comment</strong>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {report.comment}
                  </Col>
                </Row>
              </>
            }
          </Container>
        );
      })}
    />
  );
}