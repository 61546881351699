const EARLY_EXPIRE_SECONDS = 30;

// Authentication class for passing around credentials in a well-known format.
// Includes derived properties to make usage easier as well.
export class Authentication {
  username: string;
  userId: string;
  token: string;
  tokenExpiresAtMillisecond: number;
  refreshToken: string;
  refreshExpiresAtMillisecond: number;

  // Return whether or not all fields contain data.
  public get hasToken(): boolean {
    return Boolean(this.username)
      && Boolean(this.userId)
      && Boolean(this.token)
      && this.tokenExpiresAtMillisecond > 0.0
      && Boolean(this.refreshToken)
      && this.refreshExpiresAtMillisecond > 0.0;
  }

  public get expired(): boolean {
    return (Date.now() + (EARLY_EXPIRE_SECONDS * 1000)) >= this.tokenExpiresAtMillisecond;
  }

  // Return headers that can be used to set authorization on HTTP requests.
  public get headers(): Record<string, string> {
    return {
      authorization: `JWT ${this.token}`,
    }
  }

  private constructor(username: string, userId: string, token: string, tokenExpiresAtMillisecond: number, refreshToken: string, refreshExpiresAtMillisecond: number) {
    this.username = username;
    this.userId = userId;
    this.token = token;
    this.tokenExpiresAtMillisecond = tokenExpiresAtMillisecond;
    this.refreshToken = refreshToken;
    this.refreshExpiresAtMillisecond = refreshExpiresAtMillisecond;
  }

  public static fromJWT(username: string, userId: string, token: string, tokenExpiresIn: number, refreshToken: string, refreshExpiresIn: number): Authentication {
    return new Authentication(username, userId, token, tokenExpiresIn * 1000.0, refreshToken, refreshExpiresIn * 1000.0);
  }

  public static load(): Authentication {
    const jwt = JSON.parse(localStorage.getItem("jwt") || "{}");
    return new Authentication(
      jwt.username || "",
      jwt.userId || "",
      jwt.token || "",
      Number(jwt.tokenExpiresAtMillisecond || "0.0"),
      jwt.refreshToken || "",
      Number(jwt.refreshExpiresAtMillisecond || "0.0"),
    );
  }

  public static clear() {
    localStorage.removeItem("jwt");
  }

  public save(): Authentication {
    localStorage.setItem("jwt", JSON.stringify(this));
    return this;
  }
}
