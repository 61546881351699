import { Button, Form } from "react-bootstrap";
import Modal, { useModalProperties } from "./Modal";
import { useQuery } from "@apollo/client";
import { DEFAULT_REPORT_MODERATION_CATEGORY, REPORT_MODERATION_CATEGORIES, ReportModerationCategory, ReportModerationCategoryResult } from "../apollo/queries/report";
import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import { Authentication, ErrorEventHandler } from "../types";

export const DEFAULT_MODERATION_REPORT: ModerationReport = {
  category: DEFAULT_REPORT_MODERATION_CATEGORY,
  comment: "",
}

export type ModerationReport = {
  category: ReportModerationCategory;
  comment: string;
}

export type ReportModalProperties = {
  title: string;
  disabled?: boolean;
  show?: boolean;
  onClickReport: (event: React.MouseEvent<HTMLButtonElement>, report: ModerationReport) => void;
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
  onError: ErrorEventHandler;
}

export default function ReportModal({ title, disabled, show, onClickClose, onClickReport, onError }: ReportModalProperties): JSX.Element {
  const [categories, setCategories] = useState<ReportModerationCategory[]>([]);
  const [report, setReport] = useState<ModerationReport>(DEFAULT_MODERATION_REPORT);
  const { loading } = useQuery<ReportModerationCategoryResult>(REPORT_MODERATION_CATEGORIES, {
    skip: !Authentication.load().hasToken,
    onCompleted: ({ reportModerationCategories }) => {
      setCategories(reportModerationCategories);
      setReport(prev => ({ ...prev, category: reportModerationCategories[0] }));
    },
    onError,
  });
  const [modalProperties, setModalProperties] = useModalProperties({
    type: "alert",
    title,
    show,
  });

  useEffect(() => {
    if (show) {
      if (categories) {
        setReport(({ ...DEFAULT_MODERATION_REPORT, category: categories[0] }));
      } else {
        setReport(DEFAULT_MODERATION_REPORT);
      }
    }
    setModalProperties(({ show }));
  }, [categories, show, setModalProperties]);

  useEffect(() => {
    if (loading) {
      setModalProperties(({
        body: (<>
          <Logo spin />
          <p>Preparing your reporting form...</p>
        </>)
      }));
    } else {
      setModalProperties(({
        body: (<>
          <Form>
            <Form.Group>
              <Form.Label><strong>Category</strong></Form.Label>
              {categories.map((entry, idx) =>
                <Form.Check
                  key={`option-${idx}`}
                  type="radio"
                  label={entry.name}
                  disabled={disabled}
                  checked={entry === report.category}
                  onChange={e => e.target.checked && setReport(prev => ({ ...prev, category: entry }))}
                />
              )}
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label><strong>Comment (optional)</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                disabled={disabled}
                value={report.comment}
                onChange={e => setReport(prev => ({ ...prev, comment: e.target.value }))}
              />
            </Form.Group>

            <Form.Group className="d-flex justify-content-center">
              <Button
                className="mt-3 mx-2"
                variant="danger"
                disabled={disabled || report.category === DEFAULT_REPORT_MODERATION_CATEGORY}
                onClick={e => onClickReport(e, report)}
              >
                Report
              </Button>
              <Button
                className="mt-3"
                variant="secondary"
                disabled={disabled}
                onClick={onClickClose}
              >
                Cancel
              </Button>
            </Form.Group>
          </Form>
        </>)
      }));
    }
  }, [categories, disabled, loading, report, onClickClose, onClickReport, setReport, setModalProperties])

  return Modal(modalProperties);
}