import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import validateEmail from "../utils/validateEmail";
import PasswordCheckFormGroup from "./PasswordCheckFormGroup";
import { useMutation } from "@apollo/client";
import SpinnerButton from "../components/SpinnerButton";
import Modal, { useModalProperties } from "../components/Modal";
import TermsOfService from "../components/TermsOfService";
import { X } from "react-bootstrap-icons";
import { USER_SEND_EMAIL_VERIFICATION, User } from "../apollo/queries/user";
import { LOGO_URL } from "../components/Logo";
import { ErrorEventHandler, InfoEventHandler } from "../types";
import { FilePickerControl } from "../components/useFilePicker";

export type ProfileFormProps = {
  user: User;
  buttonText: string;
  passwordRequired: boolean;  // set false if this is an update form for an existing profile; true otherwise
  onError: ErrorEventHandler;
  onInfo: InfoEventHandler;
  onSubmit: (userData: User, password: string, file: File | null) => Promise<any>;
  onUserUpdate: React.Dispatch<React.SetStateAction<User>>;
}

/**
 * Validate phone numbers using only the American formats for now.
 *
 * @param phoneNumber The phone number to check.
 * @param setError The error setting function.
 */
function validatePhoneNumber(phoneNumber: string, setError: (error: string) => void) {
  if (
    phoneNumber.length > 0 &&
    !/^\+1[0-9]{10}/.test(phoneNumber) &&
    !/[0-9]{3}-[0-9]{3}-[0-9]{4}/.test(phoneNumber) &&
    !/\([0-9]{3}\) [0-9]{3}-[0-9]{4}/.test(phoneNumber)) {
    setError("Phone numbers must have the format: xxx-xxx-xxxx or (xxx) xxx-xxxx");
    return;
  }
  setError("");
}

export default function ProfileForm(
  {
    buttonText,
    passwordRequired,
    user,
    onUserUpdate,
    onInfo,
    onSubmit,
  }: ProfileFormProps
): JSX.Element {
  const tosAccepted = useRef(user.tosAccepted || false);
  const [password, setPassword_] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState(user.imageUrl || "");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [sendVerification, { loading: sendingVerification }] = useMutation(USER_SEND_EMAIL_VERIFICATION);

  const hasInputError = (passwordRequired && passwordError.length > 0) || emailError.length > 0 || phoneError.length > 0;
  const hasRequiredInput = user.email.length > 0 && (password.length > 0 || !passwordRequired);
  const formSubmissionDisabled = hasInputError || !hasRequiredInput;

  const rejectTerms = () => {
    !tosAccepted.current && onInfo(
      "Terms of Service",
      "The Terms of Service must be accepted to activate functionality.",
    );
    setTosModalProperties({ show: false });
  };

  const modalBody = (<>
    <TermsOfService
      onAccept={() => {
        onUserUpdate(prev => ({ ...prev, tosAccepted: true }));
        tosAccepted.current = true;
        setTosModalProperties(({ show: false }));
      }}
      onReject={() => rejectTerms()}
    />
  </>
  );

  const [tosModalProperties, setTosModalProperties] = useModalProperties({
    type: "info",
    title: "Terms of Service",
    body: modalBody,
    onHide: () => rejectTerms(),
  });

  const setPassword = useCallback((password: string) => {
    setPassword_(password);
    onUserUpdate(prev => ({ ...prev, password }));
  }, [setPassword_, onUserUpdate]);

  useEffect(() => {
    setImageUrl(user.imageUrl || LOGO_URL);
  }, [user.imageUrl]);

  const setImage = (file: File | null) => {
    setFile(file);
    if (file) {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      setImageUrl(URL.createObjectURL(file));
    }
    else {
      setImageUrl(LOGO_URL);
    }
  }

  return (
    <>
      <Modal {...tosModalProperties} />
      <Container>
        <Row>
          <Col>
            <Form onSubmit={e => {
              e.preventDefault();
              setLoading(true);
              onSubmit(user, password, file).then(_ => setLoading(false));
            }}
            >
              {emailError && <p className="text-danger">{emailError}</p>}
              <Form.Group>
                <Form.Label className="h6 mt-3">
                  <strong>Email</strong><span className="required-indicator">*</span>
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="email"
                    disabled={loading}
                    value={user.email}
                    onChange={(e) => {
                      validateEmail(e.target.value, setEmailError);
                      onUserUpdate(prev => ({ ...prev, email: e.target.value }));
                    }}
                  />
                  <SpinnerButton
                    variant={Boolean(user.emailVerified) ? "secondary" : "primary"}
                    disabled={Boolean(user.emailVerified)}
                    spin={sendingVerification}
                    onClick={() => sendVerification()}
                  >
                    Verify
                  </SpinnerButton>
                </div>
              </Form.Group>
              <PasswordCheckFormGroup
                disabled={loading}
                onChange={setPassword}
                onError={setPasswordError}
              />

              <Form.Group controlId="formFile">
                <Form.Label className="h6 mt-3"><strong>Profile Pic</strong></Form.Label>
                <img
                  className="m-3 profile"
                  src={imageUrl}
                  alt="profile"
                />
                <Button
                  disabled={loading || imageUrl === LOGO_URL}
                  onClick={() => {
                    setImage(null);
                    onUserUpdate(prev => ({ ...prev, imageUrl: "" }));
                    const control = document.getElementById("formFile") as HTMLInputElement;
                    if (control) {
                      control.value = "";
                    }
                  }}
                >
                  <X />
                </Button>
                <FilePickerControl
                  accept="image/*"
                  disabled={loading}
                  onChange={files => setImage(files ? files[0] : null)}
                  onError={() => { }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="h6 mt-3"><strong>Nickname</strong></Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={user.alias || ""}
                  onChange={e => onUserUpdate(prev => ({ ...prev, alias: e.target.value }))}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="h6 mt-3"><strong>First Name</strong></Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={user.firstName || ""}
                  onChange={e => onUserUpdate(prev => ({ ...prev, firstName: e.target.value }))}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="h6 mt-3"><strong>Last Name</strong></Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={user.lastName || ""}
                  onChange={e => onUserUpdate(prev => ({ ...prev, lastName: e.target.value }))}
                />
              </Form.Group>

              {phoneError && <p className="text-danger">{phoneError}</p>}
              <Form.Group className="mb-4">
                <Form.Label className="h6 mt-3"><strong>Phone</strong></Form.Label>
                <Form.Control
                  type="text"
                  disabled={loading}
                  value={user.phone || ""}
                  onChange={e => {
                    validatePhoneNumber(e.target.value, setPhoneError);
                    onUserUpdate(prev => ({ ...prev, phone: e.target.value }));
                  }}
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  className="mt-3 mb-3"
                  type="switch"
                  label={<strong>Terms of Service</strong>}
                  checked={Boolean(user.tosAccepted)}
                  onClick={() => setTosModalProperties(({ show: true }))}
                  onChange={e => e.preventDefault()}
                />
                <SpinnerButton
                  type="submit"
                  spin={loading}
                  disabled={loading || formSubmissionDisabled}
                >
                  <strong>{buttonText}</strong>
                </SpinnerButton>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>);
}