import { gql } from "@apollo/client";
import { UUID } from "crypto";

export const DEFAULT_USER: User = {
  email: "",
}

export type UserPublic = {
  username: string;
  uuid: UUID;
  imageUrl: string;
  isMe: boolean;
}

export type UserPublicResult = {
  userPublic: UserPublic;
}

export type User = {
  email: string;
  uuid?: UUID;
  alias?: string;
  isActive?: boolean;
  firstName?: string;
  lastName?: string;
  phone?: string;
  emailVerified?: boolean;
  tosAccepted?: boolean;
  newsletterSubscription?: boolean;
  imageUrl?: string;
  friendsAndFamily?: boolean;
  attributes?: UserAttribute[];
  discounts?: UserDiscount[];
}

export type UserResult = {
  me: User;
}

export type UserAttribute = {
  name: string;
  description: string;
}

export type UserDiscount = {
  slug: string;
  name: string;
  value: number;
  expiration: Date;
}

export const USER_PUBLIC_FRAGMENT = gql`
fragment UserPublic on UserPublicType {
  username
  uuid
  imageUrl
  isMe
}
`;

export const USER_GET_ME_QUERY = gql`
query UserProfile {
  me {
    email
    uuid
    isActive
    alias
    firstName
    lastName
    phone
    emailVerified
    tosAccepted
    newsletterSubscription
    imageUrl
    friendsAndFamily
    attributes {
      name
      description
    }
    discounts {
      slug
      name
      value
      expiration
    }
  }
}
`;

export const USER_PUBLIC_QUERY = gql`
${USER_PUBLIC_FRAGMENT}

query UserPublic($userId: UUID!) {
  userPublic(userId: $userId) {
    ...UserPublic
  }
}
`;

export const USER_ADD_MUTATION = gql`
mutation UserAdd(
  $email: String!,
  $password: String!,
  $tosAccepted: Boolean!,
) {
  userAdd(
    email: $email
    password: $password
    tosAccepted: $tosAccepted
  ) {
    ok
  }
}
`;

export const USER_UPDATE_MUTATION = gql`
mutation UserUpdate(
  $newEmail: String,
  $password: String,
  $alias: String,
  $firstName: String,
  $lastName: String,
  $phone: String,
  $imageUrl: String,
  $newsletterSubscription: Boolean,
  $tosAccepted: Boolean,
) {
  userUpdate(
    newEmail: $newEmail
    password: $password
    alias: $alias
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    imageUrl: $imageUrl
    newsletterSubscription: $newsletterSubscription
    tosAccepted: $tosAccepted
  ) {
    user {
      email
      isActive
      alias
      firstName
      lastName
      phone
      imageUrl
      newsletterSubscription
      tosAccepted
    }
  }
}
`;

export const USER_FORGOT_PASSWORD = gql`
mutation UserForgotPassword($email: String!) {
  userForgotPassword(email: $email) {
    ok
  }
}
`;

export const USER_RESET_PASSWORD = gql`
mutation UserResetPassword($token: String!, $password: String!) {
  userResetPassword(password: $password, token: $token) {
    ok
  }
}
`;

export const USER_SEND_EMAIL_VERIFICATION = gql`
mutation SendEmailVerification {
  userEmailSendVerification {
    ok
  }
}
`;

export const USER_EMAIL_VERIFY = gql`
mutation VerifyEmail($token: String!) {
  userEmailVerify(token: $token) {
    ok
  }
}
`;