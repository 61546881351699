import { useLazyQuery } from "@apollo/client";
import { useState, useCallback, useEffect } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { useNavigate, createSearchParams } from "react-router-dom";
import { PageInfo } from "../../apollo";
import { Order, OrderResult, ASSOCIATED_ORDERS } from "../../apollo/queries/order";
import { DEFAULT_PAGE_INFO } from "../../apollo/queries/pageinfo";
import { URLS } from "../../utils/constants";
import Logo from "../Logo";
import OrderList from "./OrderList";
import { DEFAULT_PAGE_LIMIT } from "../PaginationNavigator";

const UNLINK_ASSOCIATED_DATA: Order = {
  slug: "", // this is what causes the server to unlink
}

export type IncomingOffersProperties = {
  order: Order;
  setAssociatedOrder: (order: Order) => void;
}

export default function IncomingOffers({ order, setAssociatedOrder }: IncomingOffersProperties): JSX.Element {
  const [getIncomingOffers, { loading, fetchMore: getMoreIncomingOffers }] = useLazyQuery<OrderResult>(ASSOCIATED_ORDERS);
  const [incomingOffers, setIncomingOffersList] = useState<Order[]>([]);
  const [showOffers, setShowOffers] = useState(false);
  const [pageInfo, setPageInfo] = useState<PageInfo>(DEFAULT_PAGE_INFO);
  const [limit, setLimit] = useState(DEFAULT_PAGE_LIMIT);
  const navigate = useNavigate();
  const isUnlinked = !Boolean(order.associatedOrder?.slug);
  const [incomingOffersText, setIncomingOffersText] = useState("");

  const setIncomingOffers = useCallback(
    (result: OrderResult | undefined) => {
      setIncomingOffersList(result?.orders.edges.map(edge => edge.node) || []);
    },
    [setIncomingOffersList]
  );

  useEffect(() => {
    switch (incomingOffers.length) {
      case 0:
        setIncomingOffersText("No Offers");
        break;
      case 1:
        setIncomingOffersText("Single Offer");
        break;
      default:
        setIncomingOffersText("Multiple Offers");
        break;
    }
  }, [incomingOffers.length, limit]);

  useEffect(() => {
    if (order.slug) {
      if (isUnlinked) {
        getIncomingOffers({
          variables: {
            first: limit,
            associatedOrderSlug: order.slug,
          }
        })
          .then(({ data }) => {
            setIncomingOffers(data);
            setPageInfo({ ...(data?.orders.pageInfo || DEFAULT_PAGE_INFO) });
          });
      } else {
        setIncomingOffers(undefined);
      }
    }
  }, [order.slug, isUnlinked, limit, getIncomingOffers, setIncomingOffers]);

  if (!order.owner?.isMe) {
    return (<></>);
  }

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Fetching your offers...</strong></p>
      </div>
    );
  }

  if (isUnlinked) {
    return (
      <>
        <OrderList
          title="Incoming Offers"
          show={showOffers}
          loading={loading}
          orders={incomingOffers}
          pageInfo={pageInfo}
          onClose={() => setShowOffers(false)}
          onSelect={order => {
            navigate({
              pathname: URLS.ORDER_EDIT,
              search: createSearchParams({
                orderId: order.slug,
                associatedOrderId: order.associatedOrder?.slug || "",
              }).toString()
            });
            setShowOffers(false);
          }}
          onPreviousPage={() => getMoreIncomingOffers({
            variables: {
              first: undefined,
              last: limit,
              before: pageInfo.startCursor,
            }
          }).then(({ data }) => {
            setIncomingOffers(data);
            setPageInfo({
              ...(data.orders.pageInfo || pageInfo),
              hasNextPage: true,
            });
          })}
          onNextPage={() => getMoreIncomingOffers({
            variables: {
              after: pageInfo.endCursor,
            }
          }).then(({ data }) => {
            setIncomingOffers(data);
            setPageInfo({
              ...(data.orders.pageInfo || pageInfo),
              hasPreviousPage: true,
            });
          })}
        />
        <Form.Group>
          <Form.Label className="h6 mt-3"><strong>Incoming Offers</strong></Form.Label>
          <InputGroup>
            <OverlayTrigger
              placement="bottom-start"
              overlay={<Tooltip>Click to choose an offer.</Tooltip>}
            >
              <Form.Control
                role="button"
                className="disabled"
                readOnly
                disabled={!incomingOffers.length}
                value={incomingOffersText}
                onMouseDown={e => {
                  e.preventDefault();
                  setShowOffers(true);
                }}
              />
            </OverlayTrigger>
          </InputGroup>
        </Form.Group>
      </>
    );
  }

  return (
    <>
      <Form.Group>
        <Form.Label className="h6 mt-3"><strong>Associated Order</strong></Form.Label>
        <InputGroup>
          <OverlayTrigger
            placement="bottom-start"
            overlay={<Tooltip>{order.associatedOrder?.description}</Tooltip>}
          >
            <Form.Control
              role="button"
              className="disabled"
              readOnly
              value={order.associatedOrder?.title}
              onMouseDown={e => {
                e.preventDefault();
                navigate({
                  pathname: URLS.ORDER_EDIT,
                  search: createSearchParams({
                    orderId: order.associatedOrder?.slug || "",
                    associatedOrderId: order.slug,
                  }).toString()
                });
              }}
            />
          </OverlayTrigger>
          <Button
            variant="secondary"
            onClick={() => {
              setAssociatedOrder(UNLINK_ASSOCIATED_DATA);
            }}
          >
            <X />
          </Button>
        </InputGroup>
      </Form.Group>
    </>
  );
}