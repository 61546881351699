import { ApolloQueryResult, FetchMoreQueryOptions, OperationVariables } from "@apollo/client";
import { Button, Form } from "react-bootstrap";
import { PageInfo } from "../apollo";
import { ArrowClockwise } from "react-bootstrap-icons";
import React from "react";

export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [5, 10, 20, 50, 100];
export const DEFAULT_PAGE_LIMIT = DEFAULT_ITEMS_PER_PAGE_OPTIONS[1];
export const DEFAULT_PAGINATION_NAVIGATOR_CLASS_NAME = "d-flex flex-row justify-content-center";

export type PageLimitSelectorProperties<TData, TVariables extends OperationVariables> = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  pageInfo: PageInfo;
  pageLimit: number;
  label?: string;
  pageLimitOptions?: number[];
  onChangePageLimit: (pageLimit: number) => void;
  refetch?: (variables?: Partial<TVariables>) => Promise<ApolloQueryResult<TData>>;
  fetchMore: <TFetchData = TData, TFetchVars extends OperationVariables = TVariables>(fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData> & {
    updateQuery?: (previousQueryResult: TData, options: {
      fetchMoreResult: TFetchData;
      variables: TFetchVars;
    }) => TData;
  }) => Promise<ApolloQueryResult<TFetchData>>;
  onPreviousPage: (data: TData) => void;
  onNextPage: (data: TData) => void;
  onRefetch?: (data: TData) => void;
}

export default function PaginationNavigator<TData, TVariables extends OperationVariables>(
  {
    children,
    className,
    disabled,
    label,
    pageLimitOptions,
    onChangePageLimit,
    pageLimit,
    pageInfo,
    refetch,
    fetchMore,
    onPreviousPage,
    onNextPage,
    onRefetch,
  }: PageLimitSelectorProperties<TData, TVariables>
): JSX.Element {
  if (!Boolean(pageLimitOptions)) {
    pageLimitOptions = DEFAULT_ITEMS_PER_PAGE_OPTIONS;
  }
  return (
    <div className={className || DEFAULT_PAGINATION_NAVIGATOR_CLASS_NAME}>
      <Form.Group>
        <Button variant="secondary"
          className="me-2"
          disabled={disabled || !pageInfo.hasPreviousPage}
          onClick={() => fetchMore({
            variables: {
              first: undefined,
              last: pageLimit,
              before: pageInfo.startCursor,
            }
          }).then(({ data }) => onPreviousPage(data))}
        >
          {"<<"}
        </Button>
      </Form.Group>
      <Form.Group className="w-75">
        <Form.Select
          disabled={disabled}
          value={pageLimit}
          onChange={e => onChangePageLimit(Number.parseInt(e.target.value))}
        >
          {pageLimitOptions?.map((n, idx) =>
            <option
              key={`option-${idx}`}
              value={n}>
              {label ? `${n} ${label}` : n}
            </option>
          )}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Button variant="secondary"
          className="mx-2"
          disabled={disabled || !pageInfo.hasNextPage}
          onClick={() => fetchMore({
            variables: {
              after: pageInfo.endCursor,
            }
          }).then(({ data }) => onNextPage(data))}
        >
          {">>"}
        </Button>
      </Form.Group>
      {
        refetch && onRefetch &&
        <Form.Group>
          <Button variant="secondary"
            disabled={disabled}
            onClick={() => refetch().then(({ data }) => onRefetch(data))}
          >
            <ArrowClockwise />
          </Button>
        </Form.Group>
      }
      {children}
    </div>
  );
}