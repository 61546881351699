import { gql } from "@apollo/client";

export type S3PresignedPostCreateResult = {
  s3PresignedPostCreate: {
    data: string;
  }
}

export const S3_PRESIGNED_POST = gql`
mutation S3PresignedPostCreate($classification: String!, $filename: String!) {
  s3PresignedPostCreate(
    classification: $classification
    filename: $filename
  ) {
    data
  }
}
`;