import { useEffect, useState } from "react";
import { DEFAULT_ORDER, DEFAULT_ORDER_PACKAGE_DETAILS, ORDERS, ORDER_SET_PACKAGE_DETAILS, ORDER_WAREHOUSE_UPDATE, Order, OrderResult } from "../../apollo/queries/order";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { ThumbnailImages } from "../ThumbnailImage";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import SpinnerButton from "../SpinnerButton";
import { useLazyQuery, useMutation } from "@apollo/client";
import useFilePicker, { FilePickerControl } from "../useFilePicker";
import { URLS } from "../../utils/constants";
import PackageDetailsFormInput from "../PackageDetailsInput";
import { ErrorEventHandler } from "../../types";

type OrderInformationProperties = {
  order: Order;
  removeImage: (url: string, index: number) => void;
}

export type WarehouseIntakeFormProperties = {
  onError: ErrorEventHandler;
}

function OrderInformation({ order, removeImage }: OrderInformationProperties): JSX.Element {
  if (!order.slug) {
    return (<></>);
  }

  return (
    <Container>
      <Row>
        <Col className="h2">
          Customer's Listing Information
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td><strong>Field</strong></td>
                <td><strong>Value</strong></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Title</strong></td>
                <td>{order.title}</td>
              </tr>
              <tr>
                <td><strong>Service Tier</strong></td>
                <td>{order.serviceTier?.name}</td>
              </tr>
              <tr>
                <td><strong>Service Description</strong></td>
                <td>{order.serviceTier?.description}</td>
              </tr>
              <tr>
                <td><strong>Status</strong></td>
                <td>{order.status?.name}</td>
              </tr>
              <tr>
                <td><strong>Customer Description</strong></td>
                <td>{order.description}</td>
              </tr>
              <tr>
                <td><strong>Customer Images</strong></td>
                <td><ThumbnailImages imageUrls={order.imageUrls || []} /></td>
              </tr>
              {
                order.serviceTier?.providesDescriptionService &&
                <tr>
                  <td><strong>Warehouse Description</strong></td>
                  <td>{order.warehouseDescription}</td>
                </tr>
              }
              {
                order.serviceTier?.providesImageService &&
                <tr>
                  <td><strong>Warehouse Images</strong></td>
                  <td>
                    <ThumbnailImages
                      imageUrls={order.warehouseImageUrls || []}
                      clearable
                      onClearClick={removeImage}
                    />
                  </td>
                </tr>
              }
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default function WarehouseIntakeForm({ onError }: WarehouseIntakeFormProperties): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(searchParams.get("orderId") || "");
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER);
  const { upload, revokeIfBlob, appendFiles: setFiles } = useFilePicker({
    urls: order.warehouseImageUrls || [],
    onChange: (urls: string[]) => setOrder(prev => ({ ...prev, warehouseImageUrls: urls })),
    onError,
  });
  const [fetchOrder, { loading: fetchingOrder }] = useLazyQuery<OrderResult>(ORDERS, {
    variables: {
      slug: orderId,
    },
    onCompleted: ((data) => {
      const order = data.orders.edges.at(0);
      order && setOrder(order.node);
    }),
    onError,
  });
  const [setPackage, { loading: updatingPackageDetails }] = useMutation(ORDER_SET_PACKAGE_DETAILS, {
    refetchQueries: [ORDERS],
    // onCompleted: () => fetchOrder(),
    onError,
  });
  const [updateOrder, { loading: updatingOrder }] = useMutation<Order>(ORDER_WAREHOUSE_UPDATE, {
    refetchQueries: [ORDERS],
    // onCompleted: () => setOrder(prev => ({ ...prev, received: true })),
    onError,
  });

  const loading = fetchingOrder || updatingOrder || updatingPackageDetails;

  let disabled = loading || (order.warehouseShelf || "").length === 0;
  if (order.serviceTier?.providesDescriptionService) {
    disabled ||= (order.warehouseDescription || "").length === 0;
  }
  if (order.serviceTier?.providesImageService) {
    disabled ||= (order.warehouseImageUrls || []).length === 0;
  }

  useEffect(() => {
    if (orderId === searchParams.get("orderId")) {
      fetchOrder();
    }
  }, [orderId, searchParams, fetchOrder]);

  const lookupForm = <Form onSubmit={e => {
    e.preventDefault();
    navigate({
      pathname: URLS.ORDER_WAREHOUSE,
      search: createSearchParams({ orderId }).toString(),
    });
    navigate(0);
  }}>
    <Form.Group>
      <Form.Label><strong>Order Id</strong><span className="required-indicator">*</span></Form.Label>
      <Form.Control
        disabled={loading}
        value={orderId}
        onChange={e => setOrderId(e.target.value)}
      />
    </Form.Group>

    <Form.Group>
      <SpinnerButton
        type="submit"
        spin={loading}
        disabled={loading}
      >
        Lookup
      </SpinnerButton>
    </Form.Group>
  </Form>;

  const updateForm =
    <Form onSubmit={e => {
      e.preventDefault();

      upload(`warehouse/${order.slug}`).then(imageUrls => {
        const pkg = order.returnPackage || order.package;
        if (pkg) {
          setPackage({
            variables: {
              slug: order.slug,
              lengthInches: pkg.lengthInches,
              heightInches: pkg.heightInches,
              widthInches: pkg.widthInches,
              pounds: pkg.pounds,
              renderAsCentimeters: pkg.renderAsCentimeters,
              renderAsKilograms: pkg.renderAsKilograms,
              monetaryValueCurrency: order.package?.monetaryValueCurrency || "USD",
              monetaryValue: Number(order.package?.monetaryValue || 0.0),
              forReturn: true,
            }
          });
        }

        updateOrder({
          variables: {
            slug: order.slug,
            warehouseShelf: order.warehouseShelf,
            warehouseDescription: order.warehouseDescription,
            warehouseImageUrls: imageUrls,
          },
        });
      });
    }}>
      <PackageDetailsFormInput
        package={order.returnPackage || order.package || DEFAULT_ORDER_PACKAGE_DETAILS}
        onSetPackageDetails={details => setOrder(prev => ({ ...prev, returnPackage: details }))}
        hideInsurance
      />

      <Form.Group>
        <Form.Label><strong>Shelf</strong></Form.Label>
        <Form.Control
          disabled={loading}
          value={order.warehouseShelf}
          onChange={e => setOrder(prev => ({ ...prev, warehouseShelf: e.target.value }))}
        />
      </Form.Group>

      {
        order.serviceTier?.providesImageService &&
        <Form.Group>
          <Form.Label className="h6 mt-3">
            <strong>Add Image</strong>
            <span className="required-indicator">*</span>
          </Form.Label>
          <FilePickerControl
            autoClear
            multiple
            accept="image/*"
            onChange={setFiles}
            onError={onError}
          />
        </Form.Group>
      }

      {
        order.serviceTier?.providesDescriptionService &&
        <Form.Group>
          <Form.Label><strong>Description</strong><span className="required-indicator">*</span></Form.Label>
          <Form.Control
            as="textarea"
            rows={10}
            disabled={loading}
            value={order.warehouseDescription}
            onChange={e => setOrder(prev => ({ ...prev, warehouseDescription: e.target.value }))}
          />
        </Form.Group>
      }

      <Form.Group>
        <SpinnerButton
          type="submit"
          variant={order.received ? "secondary" : "primary"}
          spin={loading}
          disabled={disabled}
        >
          Accept Package
        </SpinnerButton>
      </Form.Group>
    </Form>;

  return (<Container>
    <Row>
      <Col>
        {lookupForm}
      </Col>
    </Row>

    <Row>
      <Col>
        <OrderInformation
          order={order}
          removeImage={(url: string, index: number) => {
            setOrder(prev => ({ ...prev, warehouseImageUrls: (prev.warehouseImageUrls || []).filter((_, idx) => idx !== index) }));
            revokeIfBlob(url);
          }}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        {order.slug && updateForm}
      </Col>
    </Row>
  </Container>);
}
