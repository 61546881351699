import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { USER_RESET_PASSWORD } from "../apollo/queries/user";
import { URLS } from "../utils/constants";
import PasswordCheckFormGroup from "./PasswordCheckFormGroup";
import SpinnerButton from "../components/SpinnerButton";

export default function PasswordResetForm(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [updatePassword] = useMutation(USER_RESET_PASSWORD);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const token = searchParams.get("token");

  const handleUpdatePasswordClick = () => {
    setLoading(true);

    updatePassword({
      variables: {
        token,
        password
      },
      onCompleted() {
        navigate(URLS.LANDING);
      },
      onError(error) {
        alert(error);
      }
    })
  };

  return (
    <Form onSubmit={e => { e.preventDefault(); handleUpdatePasswordClick(); }}>
      <PasswordCheckFormGroup
        onChange={setPassword}
        onError={setPasswordError}
      />
      <SpinnerButton
        type="submit"
        disabled={loading || passwordError.length > 0}
        spin={loading}
      >
        <strong>Update Password</strong>
      </SpinnerButton>
    </Form>
  );
}