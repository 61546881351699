import React, { useEffect, useReducer, useRef } from "react";
import { Button, Modal as BSModal } from "react-bootstrap";
import { ExclamationTriangle, InfoCircle } from "react-bootstrap-icons";
import { ButtonVariant } from "react-bootstrap/esm/types";

type ModalType = "alert" | "info";

export type ModalBaseProperties = {
  type: ModalType;
  show?: boolean;
}

export type ModalProperties = ModalBaseProperties & {
  title: string;
  body?: string | JSX.Element;
  buttonText?: string;
  buttonVariant?: ButtonVariant;
  buttonType?: "submit" | "reset" | "button";
  closeButtonText?: string;
  onHide?: () => void;
  onPrimaryButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function useModalProperties({ onHide, onPrimaryButtonClick, ...props }: ModalProperties): [ModalProperties, React.Dispatch<Partial<ModalProperties>>] {
  const isMounting = useRef(false);
  const [modalProps, setModalProps] = useReducer(((prev: ModalProperties, props: Partial<ModalProperties>) => ({
    ...prev,
    ...props,
  })), props);

  useEffect(() => {
    isMounting.current = true;
  }, []);

  useEffect(() => {
    const setHide = () => setModalProps(({ show: false }));

    if (!isMounting.current) {
      return;
    }
    isMounting.current = false;
    setModalProps({
      onHide: onHide || setHide,
      onPrimaryButtonClick: onPrimaryButtonClick || setHide,
    });
  }, [onHide, onPrimaryButtonClick]);

  return [modalProps, setModalProps];
}

function stylizeTitle(type: ModalType, title: string): JSX.Element {
  switch (type) {
    case "alert":
      return <><ExclamationTriangle className="bi" /> {title}</>;
    case "info":
      return <><InfoCircle className="bi" /> {title}</>;
  }
}

export default function Modal({ type, title, buttonText, buttonVariant, buttonType, ...props }: ModalProperties): JSX.Element {
  const showButton = Boolean(buttonText);
  const showCloseButton = Boolean(props.closeButtonText);
  const closeButtonText = props.closeButtonText || "Close";

  const footer = <BSModal.Footer>
    {
      showButton &&
      <Button
        variant={buttonVariant}
        type={buttonType}
        onClick={props.onPrimaryButtonClick}
      >
        {buttonText}
      </Button>
    }

    {
      showCloseButton &&
      <Button variant="secondary" onClick={props.onHide}>
        {closeButtonText || "Close"}
      </Button>
    }
  </BSModal.Footer>;

  return (
    <BSModal show={props.show || false}>
      <BSModal.Dialog>
        <BSModal.Header closeButton onHide={props.onHide}>
          <BSModal.Title>{stylizeTitle(type, title)}</BSModal.Title>
        </BSModal.Header>

        <BSModal.Body>
          {props.body}
        </BSModal.Body>

        {showButton && footer}
      </BSModal.Dialog>
    </BSModal>
  );
};