import { ErrorInfo } from 'react';
import { LOG_ERROR_MUTATION } from '../apollo/queries/error';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export default function logError(client: ApolloClient<NormalizedCacheObject>, error: Error, info: ErrorInfo) {
  const { name, message, stack } = error;
  const { componentStack } = info;

  const report = {
    error: {  // we seem to have to expand this manually to get the full error report
      name,
      message,
      stack,
    },
    componentStack,
  }

  // Send data to API.
  client.mutate({
    mutation: LOG_ERROR_MUTATION,
    variables: {
      message: JSON.stringify(report),
    }
  });
}
