import "./OrderForm.css";
import { useCallback, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { ORDERS, OrderResult, DEFAULT_ORDER, useOrderManager, Order } from "../../apollo/queries/order";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import Modal, { useModalProperties } from "../Modal";
import { Authentication, ErrorEventHandler, InfoEventHandler } from "../../types";
import { URLS } from "../../utils/constants";
import { ReplyFill } from "react-bootstrap-icons";
import { ThumbnailImages } from "../ThumbnailImage";
import SpinnerButton from "../SpinnerButton";
import ShareButton from "../ShareButton";
import RefreshButton from "../RefreshButton";
import ReportButton from "../ReportButton";
import { IncomingOffers, OrderCancelButton, OrderLinks, OrderModerationReport, OrderStatusPanel } from ".";
import useFilePicker, { FilePickerControl } from "../useFilePicker";

const THUMBNAIL_HEIGHT_PX = 300;
const IMAGE_MAX_BYTES = 2 ** 20;  // 1 MB
const THUMBNAIL_MAX_BYTES = 150 * 2 ** 10;  // 150 KB
const MAX_IMAGES: number = 6;

const BUTTON_CLASS_NAME = "mt-3 mb-4 mx-1";

const TXT_LINK_COPIED = "Copied";
const TXT_LINK_COPY = "Copy Link";
const TXT_LINK_SHARE = "Share";
const TXT_ASSOCIATED_ORDER_DESCRIPTION = "You will be linking with this listing.";

type OrderFormProperties = {
  onInfo: InfoEventHandler;
  onError: ErrorEventHandler;
}

type SubmitAction =
  "create_order" |
  "create_offer" |
  "update_order" |
  "accept_offer" |
  "choose_offer" |
  "send_offer";

const useSubmitAction = (order: Order, associatedOrderId: string | undefined): SubmitAction => {
  if (!order.slug) {
    return associatedOrderId
      ? "create_offer"
      : "create_order";
  }

  if (order.owner?.isMe) {
    return associatedOrderId
      ? "send_offer"
      : "update_order";
  }

  return associatedOrderId
    ? "accept_offer"
    : "choose_offer";
}

export default function OrderForm({ onError, onInfo }: OrderFormProperties): JSX.Element {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const associatedOrderId = searchParams.get("associatedOrderId") || undefined;
  const [linkCopyText, setLinkCopyText] = useState(TXT_LINK_COPY);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const submitAction = useSubmitAction(order, associatedOrderId);
  const [showReportModal, setShowReportModal] = useState(false);
  const { loading: uploadingImages, uploadImage, revokeIfBlob, appendFiles: setFiles } = useFilePicker({
    urls: order.imageUrls || [],
    onChange: (urls: string[]) => setOrder(prev => ({ ...prev, imageUrls: urls })),
    onError,
  });
  const navigateToOrder = useCallback((orderId?: string, replace?: boolean) => navigate(
    orderId
      ?
      {
        pathname: URLS.ORDER_EDIT,
        search: createSearchParams({ orderId }).toString()
      }
      : URLS.ORDER_EDIT,
    {
      replace,
    }),
    [navigate]
  );
  const { loading: fetchingOrder, refetch: refetchOrder, called: fetchedOrder } = useQuery<OrderResult>(ORDERS,
    {
      fetchPolicy: "network-only",
      skip: (
        !Boolean(orderId) || orderId === order.slug ||
        (
          Boolean(associatedOrderId) &&
          order.owner?.isMe &&
          Boolean(order.associatedOrder)
        )
      ),
      variables: {
        slug: orderId,
        public: Authentication.load().hasToken
          ? undefined
          : (searchParams.get("public")?.toLowerCase() === "true" || undefined),
      },
      onError,
      onCompleted: ({ orders: { edges: [{ node: order }] } }) => setOrder(order),
    },
  );
  const { loading: fetchingAvailableOutgoingOffers } = useQuery<OrderResult>(ORDERS,
    {
      fetchPolicy: "network-only",
      skip: !order.slug || order.owner?.isMe || Boolean(associatedOrderId),
      variables: {
        owner: Authentication.load().userId,
        associatedOrderIsNull: true,
        canceled: false,
      },
      onError,
      onCompleted: ({ orders: { edges } }) => {
        setOrderLinkModalProperties(({
          body: <OrderLinks
            orders={edges.map(edge => edge.node)}
            associatedOrder={{
              slug: order.slug,
              title: order.title,
              description: TXT_ASSOCIATED_ORDER_DESCRIPTION
            }}
            onSelect={() => setOrderLinkModalProperties(({ show: false }))}
          />,
        }))
      },
    }
  );
  const {
    loading: updatingOrder,
    includesMe: orderIncludesMe,
    createOrder,
    updateOrder,
    cancelOrder,
    approveOrder,
    rejectOrder,
    incrementOrderViewCount
  } = useOrderManager({
    order,
    onError,
    onUpdateOrderCompleted: order => setOrder(order),
    onApproveOrderCompleted: order => {
      onInfo("Order Approved!", `You approved the contents of "${order.title}"`);
      navigateToOrder(order.associatedOrder?.slug || "");
    },
    onRejectOrderCompleted: order => {
      onInfo("Order Rejected!", `You rejected the contents of "${order.title}"`);
      navigateToOrder(order.associatedOrder?.slug || "");
    },
  });
  const [orderLinkModalProperties, setOrderLinkModalProperties] = useModalProperties({
    type: "info",
    title: "Link an Order",
    buttonText: "Close",
  });

  const loading = fetchingOrder
    || fetchingAvailableOutgoingOffers
    || updatingOrder
    || uploadingImages;
  const canSubmit = (order.title || "").length > 0
    && (order.description || "").length > 0
    && order.editable
    && !order.canceled
    && !order.completed;
  const uneditable = loading || (
    Boolean(orderId) && (
      !order.editable || !order.owner?.isMe
    )
  );

  const formButtonText = ((): string | JSX.Element => {
    switch (submitAction) {
      case "create_order":
        return "Create Listing";
      case "create_offer":
        return "Create Offer";
      case "send_offer":
        return "Send Offer";
      case "update_order":
        return "Update Listing";
      case "accept_offer":
        return (<><ReplyFill className="bi" /> Accept Offer</>);
      case "choose_offer":
        return (<><ReplyFill className="bi" /> Make Offer</>);
    }
  })();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (searchParams.get("payment")) {
      searchParams.delete("payment");
      setSearchParams(searchParams);

      if (fetchedOrder) {
        refetchOrder();
      }
    } else if (!orderId) {
      setOrder(DEFAULT_ORDER);
    }
  },
    [
      fetchedOrder,
      loading,
      orderId,
      searchParams,
      refetchOrder,
      setOrder,
      setSearchParams,
    ]);

  useEffect(() => {
    if (orderId) {
      incrementOrderViewCount({
        variables: {
          slug: orderId
        }
      });
    }
  }, []);

  const onRemoveImageClick = (url: string, index: number) => {
    setOrder(prev => ({ ...prev, imageUrls: (prev.imageUrls || []).filter((_, idx) => idx !== index) }));
    revokeIfBlob(url);
  };

  const uploadImagesAndUpdateOrder = (slug?: string) => {
    if (!Boolean(slug)) {
      return;
    }
    uploadImage(
      `orders/${slug}`,
      { heightPixels: THUMBNAIL_HEIGHT_PX },
      {
        maxImageBytes: IMAGE_MAX_BYTES,
        maxThumbnailBytes: THUMBNAIL_MAX_BYTES,
      },
    ).then(imageUploadResults => {
      const imageUrls = imageUploadResults.map(result => result.imageUrl);
      setOrder(prev => ({ ...prev, imageUrls }));
      updateOrder({
        variables: {
          slug,
          imageUrls,
          linkable: order.linkable,
          public: order.public,
        },
      }).then(_ => {
        if (!orderId || associatedOrderId) {
          navigateToOrder(slug, true);
        }
      });
    }).catch(reason => onError(reason));
  };

  const form = (
    <Form onSubmit={e => {
      e.preventDefault();
      switch (submitAction) {
        case "create_order":
        case "create_offer":
          createOrder({
            variables: {
              title: order.title,
              description: order.description,
              associatedOrderSlug: associatedOrderId || order.associatedOrder?.slug,
            },
          }).then(result => {
            const slug = result.data?.orderCreate.order.slug;
            uploadImagesAndUpdateOrder(slug);
            if (slug && submitAction === "create_offer") {
              onInfo("Offer Sent", "Your offer was sent!");
            }
            return slug;
          }).catch(onError);
          break;
        case "update_order":
          updateOrder({
            variables: {
              slug: order.slug,
              title: order.title,
              description: order.description,
              imageUrls: (order.imageUrls || []).filter(url => !url.startsWith("blob:")),
              associatedOrderSlug: associatedOrderId || order.associatedOrder?.slug,
            },
          }).then(() => uploadImagesAndUpdateOrder(order.slug))
            .catch(onError);
          break;
        case "choose_offer":
          setOrderLinkModalProperties(({ show: true }));
          break;
        case "send_offer":
        case "accept_offer":
          const slug = submitAction === "send_offer"
            ? order.slug
            : associatedOrderId;
          const associatedOrderSlug = submitAction === "send_offer"
            ? associatedOrderId
            : (orderId || order.slug);
          updateOrder({
            variables: {
              slug,
              associatedOrderSlug,
            },
          }).then(() => {
            if (submitAction === "send_offer") {
              onInfo("Offer Sent", "Your offer was sent!");
              navigateToOrder(order.slug);
            } else {
              onInfo("Offer Accepted", "You accepted their offer!");
              navigateToOrder(associatedOrderId);
            }
          })
            .catch(onError);
          break;
      }
    }}>
      {
        !uneditable &&
        <>
          <Form.Group>
            <Form.Label className="h6 mt-3">
              <strong>Title</strong><span className="required-indicator">*</span>
            </Form.Label>
            <Form.Control
              disabled={uneditable}
              value={order.title}
              onChange={e => setOrder(prev => ({ ...prev, title: e.target.value }))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="h6 mt-3">
              <strong>Add Image</strong>
              <span className="required-indicator">*</span>
            </Form.Label>
            <FilePickerControl
              autoClear
              disabled={uneditable || (order.imageUrls?.length || 0) >= MAX_IMAGES}
              multiple
              count={Math.max(0, MAX_IMAGES - (order.imageUrls?.length || 0))}
              accept="image/*"
              onChange={setFiles}
              onError={onError}
            />
          </Form.Group>
          <IncomingOffers
            order={order}
            setAssociatedOrder={order => setOrder(prev => ({ ...prev, associatedOrder: order }))}
          />
        </>
      }

      {
        order.slug &&
        <Form.Group>
          <Form.Label className="h6 mt-3">
            <strong>Views</strong>
          </Form.Label>
          <Form.Control
            plaintext
            readOnly
            defaultValue={order.viewCount}
          />
        </Form.Group>
      }

      <Form.Group>
        <Form.Label className="h6 mt-3">
          <strong>Description</strong><span className="required-indicator">*</span>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={10}
          disabled={uneditable}
          value={order.description}
          onChange={e => setOrder(prev => ({ ...prev, description: e.target.value }))}
        />
      </Form.Group>

      {
        !Boolean(order.associatedOrder) &&
        <>
          <Form.Group>
            <Form.Check
              className="mt-3 mb-3"
              type="switch"
              label={<strong>Accepting Offers</strong>}
              disabled={uneditable}
              checked={order.linkable}
              onChange={e => setOrder(prev => ({
                ...prev,
                linkable: e.target.checked,
                public: e.target.checked ? order.public : false,
              }))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              className="mt-3 mb-3"
              type="switch"
              label={<strong>Make Public</strong>}
              disabled={uneditable}
              checked={order.public}
              onChange={e => {
                setOrder(prev => ({
                  ...prev,
                  public: e.target.checked,
                  linkable: order.linkable || e.target.checked,
                }));
              }}
            />
          </Form.Group>
        </>
      }

      <div className="d-flex justify-content-center">
        {
          Authentication.load().hasToken &&
          <SpinnerButton
            className={BUTTON_CLASS_NAME}
            type="submit"
            spin={loading}
            disabled={!canSubmit || loading}
            hidden={order.isFinal || !order.editable}
          >
            <strong>{formButtonText}</strong>
          </SpinnerButton>
        }
        {
          order.owner?.isMe &&
          <OrderCancelButton
            className={BUTTON_CLASS_NAME}
            disabled={!order.cancelable || loading}
            hidden={order.isFinal || !order.cancelable}
            onClick={() => cancelOrder({
              variables: {
                slug: order.slug
              }
            }).then(result => result.data?.orderCancel.order && setOrder(result.data.orderCancel.order))}
          >
            Cancel
          </OrderCancelButton>
        }
        <ShareButton
          className={BUTTON_CLASS_NAME}
          tooltip={linkCopyText}
          disabled={!orderId || loading}
          onClick={() => {
            const url = new URL(window.location.href);
            if (order.public) {
              url.searchParams.set("public", "true");
            }
            navigator.clipboard.writeText(url.toString());
            setLinkCopyText(`${TXT_LINK_COPIED} ${url}`);
          }}
          onMouseLeave={() => setLinkCopyText(TXT_LINK_COPY)}
        >
          {TXT_LINK_SHARE}
        </ShareButton>
        <RefreshButton
          className={BUTTON_CLASS_NAME}
          disabled={loading}
          hidden={order.isFinal}
          onClick={() => navigate(0)}
        >
          Refresh
        </RefreshButton>
        {
          !orderIncludesMe &&
          <ReportButton
            className={BUTTON_CLASS_NAME}
            variant="secondary"
            disabled={loading}
            hidden={!Authentication.load().hasToken || Boolean(order.owner?.isMe)}
            onClick={() => setShowReportModal(true)}
          >
            Report
          </ReportButton>
        }
      </div>
    </Form >
  );

  return (
    <>
      <Modal {...orderLinkModalProperties} />
      <OrderModerationReport
        order={order}
        show={showReportModal}
        onClose={() => setShowReportModal(false)}
        onInfo={onInfo}
        onError={onError}
      />
      <div className="h1 text-center mb-3">
        {order.title || "Untitled"}
      </div>
      <OrderStatusPanel
        order={order}
        onSetAddress={address => setOrder(prev => ({ ...prev, address }))}
        onSetPackage={dimensions => setOrder(prev => ({ ...prev, package: dimensions }))}
        onSetServiceTier={serviceTier => setOrder(prev => ({ ...prev, serviceTier }))}
        onError={onError}
        onNavigateToOrder={orderId => navigateToOrder(orderId)}
        onApprove={() => approveOrder({ variables: { slug: order.slug } })}
        onReject={() => rejectOrder({ variables: { slug: order.slug } })}
      />
      <Container>
        <Row>
          <Col>
            <ThumbnailImages
              imageUrls={
                (order.imageUrls || []).concat(
                  Array.from(
                    Array(MAX_IMAGES - (order.imageUrls?.length || 0)),
                    () => "",
                  ),
                )
              }
              onClearClick={onRemoveImageClick}
              clearable={!uneditable}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {form}
          </Col>
        </Row>
      </Container>
    </>
  );
}
