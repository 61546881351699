import { gql } from "@apollo/client";

export const DEFAULT_REPORT_MODERATION_CATEGORY = {
  slug: "",
  name: "",
  description: "",
}

export type ReportModerationCategory = {
  slug: string;
  name: string;
  description: string;
}

export type ReportModerationReason = {
  slug: string;
  violation: boolean;
  reason: string;
}

export type ReportModerationCategoryResult = {
  reportModerationCategories: ReportModerationCategory[];
}

export const REPORT_MODERATION_CATEGORIES = gql`
query ReportModerationCategories {
  reportModerationCategories {
    slug
    name
    description
  }
}
`;

export const REPORT_MODERATION_REASONS = gql`
query ReportModerationReasons {
  reportModerationReasons {
    slug
    violation
    reason
  }
}
`;