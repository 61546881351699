import { Button, ButtonProps } from "react-bootstrap";
import trackingUrl from "../utils/tracking";

export type TrackingButtonProperties = ButtonProps & {
  trackingNumber: string;
}

export function TrackingButton(props: TrackingButtonProperties): JSX.Element {
  const { trackingNumber, children, ...remainingProps } = props;
  return (
    <Button
      {...remainingProps}
      onClick={() => window.open(trackingUrl(trackingNumber), "_blank")}
    >
      {children}
    </Button>
  );
}