import { Tooltip, OverlayTrigger, Table } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { useNavigate, createSearchParams } from "react-router-dom";
import { Order } from "../../apollo/queries/order";
import { URLS } from "../../utils/constants";

const TXT_ORDER_NEW = "New Listing";
const TXT_TOOLTIP_ORDER_CREATE = "Create a new listing.";

export type OrderLinksProperties = {
  orders: Order[];
  associatedOrder: Order;
  onSelect: () => void;
}

export default function OrderLinks({ orders, associatedOrder, onSelect }: OrderLinksProperties): JSX.Element {
  const navigate = useNavigate();

  const select = (orderId?: string) => {
    navigate(
      {
        pathname: URLS.ORDER_EDIT,
        search: createSearchParams(
          orderId
            ?
            {
              orderId,
              associatedOrderId: associatedOrder.slug,
            }
            : { associatedOrderId: associatedOrder.slug }
        ).toString()
      }
    );
    onSelect();
  }

  const tooltip = (tip: string) => (<Tooltip>{tip}</Tooltip>);

  const body = orders.map((order, idx) => {
    const createdDate = order.createdDate
      ? new Date(Date.parse(order.createdDate))
      : new Date();
    return (
      <OverlayTrigger key={`overlay-${idx}`} placement="bottom" overlay={tooltip(order.description || "")}>
        <tr role="button" onClick={() => select(order.slug)}>
          <td>
            <span role="link"><BoxArrowUpRight className="bi" /> {order.title}</span>
          </td>
          <td>{createdDate.toLocaleDateString()}</td>
          <td>{createdDate.toLocaleTimeString()}</td>
        </tr>
      </OverlayTrigger>
    )
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <td><strong>Title</strong></td>
          <td><strong>Date Created</strong></td>
          <td><strong>Time Created</strong></td>
        </tr>
      </thead>
      <tbody>
        <OverlayTrigger placement="bottom" overlay={tooltip(TXT_TOOLTIP_ORDER_CREATE)}>
          <tr role="button" onClick={() => select()}>
            <td>
              <BoxArrowUpRight className="bi" /> {TXT_ORDER_NEW}
            </td>
            <td></td>
            <td></td>
          </tr>
        </OverlayTrigger>
        {body}
      </tbody>
    </Table>
  );
}