import './FAQ.css'
import { Accordion, ListGroup } from 'react-bootstrap';

export default function FAQ() {
  return (
    <div>
      <h2 className="text-center mb-4">Frequently Asked Questions</h2>
      <Accordion className="faq-accordian mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header><strong>Is this a subscription service?</strong></Accordion.Header>
          <Accordion.Body>
            TELAPORT is a <strong>Pay-per-Trade (PPT)</strong> service. That means every TELAPORT request there is only one service fee. There are no hidden costs or fees. The two main fees are the TELAPORT service fee ($10, $15, or $25) & Shipping costs.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><strong>How do I make a TELAPORT Trade request?</strong></Accordion.Header>
          <Accordion.Body>
            You only need a <strong>Title</strong>, <strong>Description</strong>, and optionally <strong>Photos</strong>. After that you will receive a <strong>unique TELAPORT link</strong> that you will be able to share with anyone, for that specific trade TELAPORT request.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><strong>What information will the TELAPORT request show?</strong></Accordion.Header>
          <Accordion.Body>
            The only information in a TELAPORT request that is sent to another person are the <strong>photos, title, and description</strong> of your goods. Other users will <strong>NOT see</strong> which TELAPORT <strong>service you chose</strong> or how you shipped your item.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><strong>STAY SAFE!!! READ THESE WARNINGS</strong></Accordion.Header>
          <Accordion.Body>
            <strong>NEVER</strong> share the following with the anyone else;
            <ListGroup className="mt-4 mb-4">
              <ListGroup.Item>•	Which service you chose,</ListGroup.Item>
              <ListGroup.Item>•	Your order number,</ListGroup.Item>
              <ListGroup.Item>•	Shipping Warehouse Location</ListGroup.Item>
            </ListGroup>
            <strong>NEVER</strong> let anyone tell you which service to choose. We highly recommend <strong>Basic</strong> tier for ensuring the other party sent you the correct item, and <strong>Pro</strong> tier for condition sensitive items that may need photo verification for yourself.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
