import SpinnerButton, { SpinnerButtonProps } from "../SpinnerButton";
import Modal, { useModalProperties } from "../Modal";
import { useEffect } from "react";
import { X } from "react-bootstrap-icons";

export default function OrderCancelButton({ children, onClick, ...props }: SpinnerButtonProps): JSX.Element {
  const [modalProps, setModalProps] = useModalProperties({
    type: "alert",
    title: "Cancel Listing?",
    body: "Are you sure you want to cancel your listing?",
    buttonText: "Yes",
    buttonVariant: "danger",
    closeButtonText: "No",
  });

  useEffect(
    () => setModalProps(({
      onPrimaryButtonClick: e => {
        onClick && onClick(e);
        setModalProps(({ show: false }));
      }
    })),
    [onClick, setModalProps]
  );

  return (<>
    <Modal {...modalProps} />

    <SpinnerButton
      variant="danger"
      onClick={() => setModalProps(({ show: true }))}
      {...props}
    >
      <X className="bi" /> {children}
    </SpinnerButton>
  </>);
}