import React from "react";
import { Button, ButtonProps, Spinner as BaseSpinner } from "react-bootstrap";

export interface SpinnerButtonProps extends ButtonProps {
  spin?: boolean;
};

function Spinner(): JSX.Element {
  return (
    <BaseSpinner
      as="span"
      size="sm"
      animation="border"
      role="status"
    />
  );
}

export default function SpinnerButton(
  {
    children,
    spin,
    type,
    ...props
  }: SpinnerButtonProps
): JSX.Element {
  return (
    <Button
      type={type || "button"}
      {...props}
    >
      {spin ? <Spinner /> : children}
    </Button>
  );
}
