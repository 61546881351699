import Modal, { ModalBaseProperties, ModalProperties } from "./Modal";

export type NavigableModalProperties = ModalBaseProperties & {
  title: string;
  message: string | JSX.Element;
  hasNext: boolean;
  onNextClick: () => void;
  onCloseClick: () => void;
}

export default function NavigableModal(
  { type, show, title, message, hasNext, onNextClick, onCloseClick }: NavigableModalProperties
): JSX.Element {
  const modalProperties: ModalProperties = {
    type,
    show,
    title,
    body: message,
    buttonText: hasNext ? ">>" : "Close",
    onHide: onCloseClick,
    onPrimaryButtonClick: hasNext ? onNextClick : onCloseClick,
  };

  return (<Modal {...modalProperties} />);
}
