import { MutationResult, useMutation } from '@apollo/client';
import { LOGIN_USER_MUTATION } from "./queries";
import { Authentication } from '../../types';

export type JWTLogin = {
  auth: Authentication;
  rest: MutationResult<any>[];
}

export default function useJWTLogin(): (username: string, password: string) => Promise<JWTLogin> {
  const tokenToAuthentication = (tokenAuth: any) =>
    Authentication.fromJWT(
      tokenAuth.payload.username,
      tokenAuth.payload.userId,
      tokenAuth.token,
      tokenAuth.payload.exp,
      tokenAuth.refreshToken,
      tokenAuth.refreshExpiresIn,
    );

  const [login, ...rest] = useMutation(LOGIN_USER_MUTATION, { refetchQueries: "all" });
  return (username: string, password: string) => login(
    {
      variables: {
        username,
        password,
      },
      refetchQueries: "active",
    }
  )
    .then(({ data: { tokenAuth } }) => ({
      auth: tokenToAuthentication(tokenAuth).save(),
      rest,
    })
    );
}
