import React from 'react';
import './SplitLandingHero.css'
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { URLS } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import { Authentication } from '../types';

const carouselStyle = {
    paddingTop: '50px', // Adjust the top padding for the carousel
};

const SplitLandingHero = () => {
    const navigate = useNavigate();

    return (
        <Container fluid className="hero-section mb-4">
            <Row className="align-items-center padded-hero-row">
                <Col md={6} className="mb-4 mt-4 hero-left d-flex flex-column align-items-center">
                    <h1 className="text-center mb-4"><strong>You Should Keep YOUR Profits!</strong></h1>
                    <h4 className='text-center mb-4'><strong>Why do other companies take value away from their customers?</strong></h4>
                    <h4 className="lead text-center mb-4">Because it's WAY more profitable for a company like eBay or Amazon to take a % of YOUR money and provide no extra service.</h4>
                    <h4 className="lead text-center">TELAPORT provides the first eCommerce Marketplace to buy, sell, and trade! We provide a fraud preventitive middle-man service that makes trading MORE PROFITABLE and cost-effective compared to other platforms who require bank account information, social numbers, and other personal info. Through Stripe® you can pay however you like!</h4>
                    <div className="d-flex justify-content-center">
                        <Button variant="primary" className="mt-4"
                            onClick={() => navigate(URLS.USER_SIGNUP)}
                            hidden={Authentication.load().hasToken}
                        >
                            <strong>FREE Sign Up</strong>
                        </Button>
                    </div>
                </Col>
                {/* <Col md={6} className="hero-right">
                    <img
                        src="/static/images/logo_with_bg.png"
                        alt="Telaport Logo with Yellow Background"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                </Col> */}
                <Col lg={6} className="hero-carousel" style={carouselStyle}>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block mx-auto img-fluid"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/video_segment.png"
                                alt="Shows a phone with a listing on someones phone showing a pokemon box for sale. Shows how you can share the link with other people on various websites and groups. Another smaller hand is holding and looking at the listing of he pokemon box that was shared."
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/card_party_banner_2.png"
                                alt="A Pokemon card and open package in Background. Telaport logo in the front. Trade cards. collectibles. anything. with anyone. Telaport.net."
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="https://telaport-prod.nyc3.cdn.digitaloceanspaces.com/html/static/images/clothes_banner.png"
                                alt="A purse, sweater, and open box in Background. Telaport logo in the front. Trade clothes. collectibles. anything. with anyone. Telaport.net."
                            />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default SplitLandingHero;