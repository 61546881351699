import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SpinnerButton from "../components/SpinnerButton";
import { DEFAULT_ORDER_OWNER_ADDRESS, Order, OrderOwnerAddress } from "../apollo/queries/order";
import { US_STATES } from "../utils/addressConstants";

type AddressFormProperties = {
  order: Order;
  onClose: () => void;
  onSetAddress: (address: OrderOwnerAddress) => Promise<void>;
}

export default function AddressForm({ order, onClose, onSetAddress }: AddressFormProperties): JSX.Element {
  const [address, setAddress] = useState(order.address || DEFAULT_ORDER_OWNER_ADDRESS);
  const [loading, setLoading] = useState(false);
  const canSubmit = address.name
    && JSON.parse(address.addressLine).length > 0
    && address.city
    && address.stateProvinceCode
    && address.postalCode
    && address.countryCode;

  return (
    <Form onSubmit={e => {
      e.preventDefault();
      setLoading(true);
      onSetAddress(address).finally(() => setLoading(false));
    }}>
      <Container>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label><strong>Name</strong></Form.Label>
              <Form.Control
                placeholder="Full Name"
                value={address.name}
                onChange={e => setAddress(prev => ({ ...prev, name: e.target.value }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label><strong>Attention To (Optional)</strong></Form.Label>
              <Form.Control
                placeholder="Name"
                value={address.attentionName}
                onChange={e => setAddress(prev => ({ ...prev, attentionName: e.target.value }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label><strong>Address</strong></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Address (Maximum of 3 Lines)"
                value={JSON.parse(address.addressLine).join("\n")}
                onChange={e => setAddress(prev => {
                  const addressLine = e.target.value.split("\n");
                  if (addressLine.length > 3) {
                    return prev;
                  }
                  return { ...prev, addressLine: JSON.stringify(addressLine) }
                })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label><strong>City</strong></Form.Label>
              <Form.Control
                placeholder="City"
                value={address.city}
                onChange={e => setAddress(prev => ({ ...prev, city: e.target.value }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label><strong>State or Province Code</strong></Form.Label>
              <Form.Select
                value={address.stateProvinceCode}
                onChange={e => setAddress(prev => ({ ...prev, stateProvinceCode: e.target.value }))}
              >
                <option value="">Select State</option>
                {Object.entries(US_STATES).map((entry, idx) =>
                  <option
                    key={`option-${idx}`}
                    value={entry[1]}
                  >
                    {entry[0]}
                  </option>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label><strong>Postal Code</strong></Form.Label>
              <Form.Control
                placeholder="Postal Code"
                value={address.postalCode}
                onChange={e => setAddress(prev => ({ ...prev, postalCode: e.target.value }))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                className="mt-3"
                type="switch"
                label={<strong>Residential Address</strong>}
                checked={address.residential}
                onChange={e => setAddress(prev => ({ ...prev, residential: e.target.checked }))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>


      <div className="d-flex justify-content-end">
        <SpinnerButton
          type="submit"
          spin={loading}
          disabled={!canSubmit || loading}
          className="mt-3 mx-1"
        >
          <strong>{order.address ? "Update" : "Set"}</strong>
        </SpinnerButton>
        <Button
          variant="secondary"
          className="mt-3 mx-1"
          type="button"
          disabled={loading}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Form>
  );
}