import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { ApolloError, RefetchQueriesInclude, useMutation } from "@apollo/client";
import { Order, OrderOwnerAddress, ORDER_SET_ADDRESS } from "../apollo/queries/order";
import Modal, { useModalProperties } from "../components/Modal";
import AddressForm from "../forms/AddressForm";

type AddressButtonProperties = {
  order: Order;
  refetchQueries: RefetchQueriesInclude;
  onSetAddress: (address: OrderOwnerAddress) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
}

export default function AddressButton({ order, refetchQueries, onSetAddress, onError }: AddressButtonProperties): JSX.Element {
  const [setAddress] = useMutation(ORDER_SET_ADDRESS, { refetchQueries });
  const title = order.address ? "Update Your Address" : "Add Your Address";
  const [packageModalProperties, setPackageModalProperties] = useModalProperties({
    type: "info",
    title,
  });

  useEffect(() => {
    const formOnClose = () => setPackageModalProperties(({ show: false }));
    setPackageModalProperties(({
      body: <AddressForm
        order={order}
        onSetAddress={address =>
          setAddress({
            variables: {
              slug: order.slug,
              name: address.name,
              attention: address.attentionName,
              addressLine: JSON.parse(address.addressLine),
              city: address.city,
              stateProvinceCode: address.stateProvinceCode,
              postalCode: address.postalCode,
              countryCode: address.countryCode,
              residential: address.residential,
            }
          }).then(({ data: { orderSetAddress: { order } } }) => onSetAddress && onSetAddress(order.address))
            .then(formOnClose)
            .catch(onError)
        }
        onClose={formOnClose} />
    }))
  }, [order, onError, onSetAddress, setAddress, setPackageModalProperties]);

  const onClick = () => setPackageModalProperties(({ show: true }));

  if (!Boolean(order.warehousePaymentStatus?.paid)) {
    return (
      <>
        <Modal {...packageModalProperties} />
        <Button className="mx-1 mt-3"
          variant={order.address ? "secondary" : "primary"}
          onClick={onClick}
        >
          {title}
        </Button>
      </>
    )
  }

  return (<></>);
}