import './Store.css';
import { useCallback, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { ORDERS, Order, OrderResult } from '../apollo/queries/order';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { URLS } from '../utils/constants';
import { DEFAULT_PAGE_INFO } from '../apollo/queries/pageinfo';
import Logo, { LOGO_URL } from './Logo';
import PaginationNavigator, { DEFAULT_PAGE_LIMIT } from './PaginationNavigator';
import { FileEarmark } from 'react-bootstrap-icons';
import { createObjectURLFromSVG } from '../utils';
import { USER_PUBLIC_QUERY, UserPublic, UserPublicResult } from '../apollo/queries/user';
import { filenameAsThumbnail } from './useFilePicker';

const DESCRIPTION_LENGTH_LIMIT = 50;

interface ImageCardProps {
  order: Order;
}

function ImageCard({ order }: ImageCardProps) {
  const navigate = useNavigate();
  const imageUrl = order.imageUrls && order.imageUrls.length > 0
    ? order.imageUrls[0]
    : createObjectURLFromSVG(<FileEarmark />);
  const description = (order.description || "").trim();

  return (
    <Card
      style={{ width: '18rem' }}
      onClick={() => navigate({
        pathname: URLS.ORDER_EDIT,
        search: createSearchParams({ orderId: order.slug }).toString(),
      })}
      className="cursor-pointer mx-3"
    >
      <div className="image-crop">
        <Card.Img
          className="mt-2"
          variant="top"
          src={filenameAsThumbnail(imageUrl)}
          onError={e => e.currentTarget.src = imageUrl}
        />
      </div>
      <Card.Body>
        <Card.Title>{order.title}</Card.Title>
        <Card.Text>
          {
            description.length > DESCRIPTION_LENGTH_LIMIT
              ? description.substring(0, DESCRIPTION_LENGTH_LIMIT).trim().concat("...")
              : description
          }
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export type StoreProperties = {
  owner?: string;
  randomize?: boolean;
  embed?: boolean;
  displayLimit?: number;
  ordering?: string;
}

export default function Store({
  randomize = false,
  embed = false,
  displayLimit,
  ...props
}: StoreProperties): JSX.Element {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const ownerId = props.owner || searchParams.get("owner");
  const ordering = props.ordering || searchParams.get("ordering") || undefined;
  const [searchTerms, setSearchTerms] = useState("");
  const [owner, setOwner] = useState<UserPublic>();
  const [orders, setOrders] = useState<Order[]>([]);
  const [pageInfo, setPageInfo] = useState(DEFAULT_PAGE_INFO);
  const [pageLimit, setPageLimit] = useState(DEFAULT_PAGE_LIMIT);

  const goSearch = useCallback((search: string) => {
    if (embed) {
      navigate({
        pathname: URLS.STORE,
        search: createSearchParams({ search }).toString(),
      });
      return;
    }
    searchParams.set("search", search);
    setSearchParams(searchParams);
  }, [embed, searchParams, navigate, setSearchParams]);

  const { loading: loadingUser } = useQuery<UserPublicResult>(USER_PUBLIC_QUERY, {
    variables: {
      userId: ownerId,
    },
    skip: !Boolean(ownerId),
    onCompleted: ({ userPublic }) => setOwner(userPublic),
  })

  const { loading: loadingOrders, fetchMore, refetch } = useQuery<OrderResult>(ORDERS, {
    variables: {
      first: pageLimit,
      linkable: true,
      public: true,
      associatedOrderIsNull: true,
      canceled: false,
      owner: owner?.uuid,
      ordering,
      search,
    },
    skip: Boolean(ownerId) && !Boolean(owner?.uuid),
    onCompleted: ({ orders }) => {
      setOrders(orders.edges.map(edge => edge.node));
      setPageInfo(orders.pageInfo);
    }
  });
  const loading = loadingUser || loadingOrders;

  const navigationRow = <PaginationNavigator
    label="Listings"
    pageInfo={pageInfo}
    pageLimit={pageLimit}
    onChangePageLimit={setPageLimit}
    refetch={refetch}
    fetchMore={fetchMore}
    onPreviousPage={data => {
      setOrders(data.orders.edges.map(edge => edge.node) || []);
      setPageInfo({
        ...(data.orders.pageInfo || pageInfo),
        hasNextPage: true,
      });
    }}
    onNextPage={data => {
      setOrders(data.orders.edges.map(edge => edge.node) || []);
      setPageInfo({
        ...(data.orders.pageInfo || pageInfo),
        hasPreviousPage: true,
      });
    }}
    onRefetch={data => {
      setOrders(data.orders.edges.map(edge => edge.node) || []);
      setPageInfo(data.orders.pageInfo || pageInfo);
    }}
  />;

  const searchRow = (
    <Row>
      <Col className="d-flex justify-content-center">
        <Form.Control
          className="w-75 me-3"
          type="text"
          placeholder="Search for what you want..."
          value={searchTerms}
          onKeyDown={e => e.key.toLowerCase() === "enter" && goSearch(searchTerms)}
          onChange={e => setSearchTerms(e.target.value)}
        />
        <Button onClick={() => goSearch(searchTerms)}>
          Search
        </Button>
      </Col>
    </Row>
  );

  const orderCards = orders
    .map((order, index) => (
      <Col key={index}>
        <ImageCard order={order} />
      </Col>
    )).filter((_, idx) => idx < (displayLimit || pageLimit))

  if (loading) {
    return (
      <Container className="text-center">
        {searchRow}
        <Row className="text-center mt-3">
          <Col>
            <Logo spin />
            <p><strong>Fetching public listings...</strong></p>
          </Col>
        </Row>
      </Container >
    );
  }

  if (embed) {
    return (<>
      <Container>
        {searchRow}
        <Row className="row-cols-md-3 g-4 justify-content-center mt-1">
          {orderCards}
        </Row>
      </Container>
    </>);
  }

  return (
    <div className="App bg-light w-100">
      <Container className='h1 text-center'>
        {
          owner &&
          <Row>
            <Col>
              <img
                className="profile"
                src={owner.imageUrl ? owner.imageUrl : LOGO_URL}
                alt="logo"
              />
            </Col>
          </Row>
        }
        <Row className="mt-3">
          <Col>
            {
              owner
                ? owner.username.concat("'s Store")
                : "Marketplace"
            }
          </Col>
        </Row>
      </Container>
      <Container>
        {searchRow}
        {
          orders.length === 0 &&
          <Row>
            <Col className="mt-3 text-center h2">
              No listings were found.
            </Col>
          </Row>
        }
        {
          orders.length > 0 &&
          <>
            <Row>
              <Col className="my-3">
                {navigationRow}
              </Col>
            </Row>
            <Row className="row-cols-md-3 g-4 justify-content-center">
              {orderCards}
            </Row>
            <Row>
              <Col className="mt-3">
                {navigationRow}
              </Col>
            </Row>
          </>
        }
      </Container>
    </div>
  );
}