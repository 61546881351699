import "./AuthenticationForm.css";
import { useReducer, useState } from "react";
import Row from 'react-bootstrap/Row';
import Logo from "../components/Logo";
import { ApolloError, useMutation } from '@apollo/client';
import { USER_ADD_MUTATION } from "../apollo/queries/user";
import { Form, Button, Col, Spinner, Container } from 'react-bootstrap';
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useJWTLogin } from "../apollo";
import { URLS } from "../utils/constants";
import PasswordCheckFormGroup from "./PasswordCheckFormGroup";
import validateEmail from "../utils/validateEmail";
import SpinnerButton from "../components/SpinnerButton";
import Modal, { useModalProperties } from "../components/Modal";
import TermsOfService from "../components/TermsOfService";

const start = "Your Step Ahead Starts Here.";

interface LogoProps {
  text?: string;
}

type AuthenticationChildProps = {
  onInfo?: (title: string, body: string | JSX.Element) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
}

type AuthenticationFormProps = AuthenticationChildProps & {
  type: "login" | "signup";
}

function ShowLogo({ text }: LogoProps) {
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Logo />
      {
        text &&
        <div className="d-flex justify-content-center welcome">
          {text}
        </div>
      }
    </Row>
  );
}

function SignUp({ onInfo: setInfo, onError }: AuthenticationChildProps) {
  const navigate = useNavigate();
  const login = useJWTLogin();
  const [loggingIn, setLoggingIn] = useState(false);

  const [addUser, { loading: addingUser }] = useMutation(USER_ADD_MUTATION,
    {
      onCompleted: () => {
        setLoggingIn(true);
        login(email, password).then(async () => {
          navigate(URLS.USER_PROFILE);
          setInfo && setInfo(
            "Profile Created",
            "".concat(
              `Your profile has been created! Please check your email at ${email} for a verification link. `,
              "Verification is required before you can take part in the marketplace.",
            )
          );
          setLoggingIn(false);
        });
      },
      onError,
    }
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [tosAccepted, setTosAccepted] = useReducer((_: boolean, tosAccepted: boolean) => {
    if (tosAccepted) {
      addUser({
        variables: {
          email,
          password,
          tosAccepted,
        }
      });
    }
    return tosAccepted;
  }, false);

  const rejectTerms = () => {
    !tosAccepted && setInfo && setInfo("Terms of Service", "The Terms of Service must be accepted to complete sign-up.");
    setTosModalProperties({ show: false });
  }

  const [tosModalProperties, setTosModalProperties] = useModalProperties({
    type: "info",
    title: "Terms of Service",
    body: <TermsOfService
      onAccept={() => {
        !tosAccepted && setTosAccepted(true);
        setTosModalProperties(({ show: false }));
      }}
      onReject={rejectTerms}
    />,
    onHide: rejectTerms,
  });

  const hasInputError = passwordError.length > 0 || emailError.length > 0;
  const hasRequiredInput = email.length > 0 && password.length > 0;
  const formSubmissionDisabled = hasInputError || !hasRequiredInput;

  const loading = addingUser || loggingIn;

  const onSignUpClick = () => {
    setTosModalProperties({ show: true });
  };

  return (
    <Col md={6}>
      <Modal {...tosModalProperties} />
      <ShowLogo text="Welcome!" />

      <Form onSubmit={e => {
        e.preventDefault();

        if (!formSubmissionDisabled) {
          onSignUpClick();
        }
      }}
      >
        {emailError && <p className="text-danger">{emailError}</p>}
        <Form.Group>
          <Form.Label className="h6 mt-3"><strong>Email</strong><span className="required-indicator">*</span></Form.Label>
          <Form.Control
            type="email"
            disabled={loading}
            value={email}
            onChange={(e) => {
              const email = e.target.value.toLowerCase();
              validateEmail(email, setEmailError);
              setEmail(email);
            }}
          />
        </Form.Group>

        <PasswordCheckFormGroup
          disabled={loading}
          onChange={setPassword}
          onError={setPasswordError}
        />

        <SpinnerButton
          className="mt-3"
          type="submit"
          spin={loading}
          disabled={loading || formSubmissionDisabled}
        >
          <strong>Sign Up</strong>
        </SpinnerButton>
      </Form>
    </Col>
  )
}

function Login({ onError }: AuthenticationChildProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const login = useJWTLogin();

  const onLoginClick = (username: string, password: string) => {
    onError("");
    login(username, password)
      .then(() => {
        const from = searchParams.get("from");
        if (from) {
          const { pathname, searchParams: params } = new URL(from);
          return { pathname, search: createSearchParams(params).toString() };
        }
        else {
          return URLS.LANDING;
        }
      })
      .then(to => navigate(to, { replace: true }))
      .catch(({ message }) => {
        onError(`${message}.`);
        setLoading(false);
      });
  };

  return (
    <Col md={6}>
      <ShowLogo text="Welcome back!" />

      <Form onSubmit={e => {
        e.preventDefault();
        setLoading(true);
        onLoginClick(username, password);
      }}
      >
        <Form.Group className="p-4">
          <Form.Label className="h6"><strong>Email</strong></Form.Label>
          <Form.Control
            type="email"
            disabled={loading}
            value={username}
            onChange={(e) => setUsername(e.target.value.toLowerCase())}
          />
        </Form.Group>

        <Form.Group className="p-4">
          <Form.Label className="h6"><strong>Password</strong></Form.Label>
          <Form.Control type="password" disabled={loading} onChange={(e) => setPassword(e.target.value)} />
        </Form.Group>

        <Form.Group className="float-end">
          <Button variant="link" onClick={() => navigate(URLS.USER_SEND_PASSWORD_RESET)}>
            Forgot password?
          </Button>
        </Form.Group>

        <Button
          variant="primary"
          className="w-100 mt-3"
          type="submit"
          disabled={loading}
        >
          <Spinner as="span" size="sm" animation="border" hidden={!loading} role="status" />
          {loading && <>&nbsp;</>}
          <strong>Sign in</strong>
        </Button>
      </Form>

      <div className="text-center mt-3">Don't have an account?</div>
      <div className="text-center">
        <Button variant="link" onClick={() => navigate(URLS.USER_SIGNUP)}>Sign up</Button>
      </div>
    </Col>
  );
}

export default function AuthenticationForm({ type, onInfo, onError }: AuthenticationFormProps) {
  let element: JSX.Element;
  switch (type) {
    case "login":
      element = <Login onError={onError} />
      break;
    case "signup":
      element = <SignUp onInfo={onInfo} onError={onError} />
      break;
  }

  return (
    <Container className="mt-auto">
      <Row className="h-100">
        {element}
        <Col className="col-md-6 d-md-block d-none telaport-yellow-bg">
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <figure className="text-center m-0">
              <blockquote className="blockquote">
                <p className="h2"><strong>{start}</strong></p>
              </blockquote>
              <figcaption className="blockquote-footer figcaption-large">
                The Telaport Team
              </figcaption>
            </figure>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
