import { ArrowClockwise } from "react-bootstrap-icons";
import SpinnerButton, { SpinnerButtonProps } from "./SpinnerButton";

export default function RefreshButton({ children, ...props }: SpinnerButtonProps): JSX.Element {
  return (
    <SpinnerButton
      variant="secondary"
      {...props}
    >
      <ArrowClockwise className="bi" /> {children}
    </SpinnerButton>
  );
}