import { Megaphone } from "react-bootstrap-icons";
import SpinnerButton, { SpinnerButtonProps } from "./SpinnerButton";

export default function ReportButton({ children, ...props }: SpinnerButtonProps): JSX.Element {
  return (
    <SpinnerButton
      variant="secondary"
      {...props}
    >
      <Megaphone className="bi me-1" /> {children}
    </SpinnerButton>
  );
}