import React from "react";
import { Form } from "react-bootstrap";
import { OrderPackageDetails } from "../apollo/queries/order";

const MAX_INSURANCE = 50000.00;

type PackageDetailsFormInputProperties = {
  package: OrderPackageDetails;
  hideInsurance?: boolean;
  onSetPackageDetails: (details: OrderPackageDetails) => void;
}

export default function PackageDetailsFormInput({
  package: details,
  hideInsurance,
  onSetPackageDetails
}: PackageDetailsFormInputProperties
): JSX.Element {
  return (<>
    <Form.Group>
      <Form.Label><strong>L x W x H</strong>&nbsp;</Form.Label>
      <Form.Check type="radio" label="in"
        inline
        checked={!details.renderAsCentimeters}
        onChange={() => onSetPackageDetails(({ ...details, renderAsCentimeters: false }))}
      />
      <Form.Check type="radio" label="cm"
        inline
        checked={details.renderAsCentimeters}
        onChange={() => onSetPackageDetails(({ ...details, renderAsCentimeters: true }))}
      />
      <Form.Control
        type="number"
        placeholder="###"
        step={0.1}
        min={0.0}
        value={details.renderAsCentimeters ? details.lengthCentimeters : details.lengthInches}
        onChange={e => {
          const value = Number(e.target.value);
          if (details.renderAsCentimeters) {
            onSetPackageDetails(({ ...details, lengthInches: value / 2.54, lengthCentimeters: value }));
          } else {
            onSetPackageDetails(({ ...details, lengthInches: value, lengthCentimeters: value * 2.54 }));
          }
        }}
      />
      <Form.Control
        type="number"
        placeholder="###"
        step={0.1}
        min={0.0}
        value={details.renderAsCentimeters ? details.widthCentimeters : details.widthInches}
        onChange={e => {
          const value = Number(e.target.value);
          if (details.renderAsCentimeters) {
            onSetPackageDetails(({ ...details, widthInches: value / 2.54, widthCentimeters: value }));
          } else {
            onSetPackageDetails(({ ...details, widthInches: value, widthCentimeters: value * 2.54 }));
          }
        }}
      />
      <Form.Control
        type="number"
        placeholder="###"
        step={0.1}
        min={0.0}
        value={details.renderAsCentimeters ? details.heightCentimeters : details.heightInches}
        onChange={e => {
          const value = Number(e.target.value);
          if (details.renderAsCentimeters) {
            onSetPackageDetails(({ ...details, heightInches: value / 2.54, heightCentimeters: value }));
          } else {
            onSetPackageDetails(({ ...details, heightInches: value, heightCentimeters: value * 2.54 }));
          }
        }}
      />
    </Form.Group>

    <Form.Group>
      <Form.Label><strong>Weight</strong>&nbsp;</Form.Label>
      <Form.Check type="radio" inline label="lbs"
        checked={!details.renderAsKilograms}
        onChange={() => onSetPackageDetails(({ ...details, renderAsKilograms: false }))}
      />
      <Form.Check type="radio" inline label="kgs"
        checked={details.renderAsKilograms}
        onChange={() => onSetPackageDetails(({ ...details, renderAsKilograms: true }))}
      />
      <Form.Control
        type="number"
        placeholder="###"
        step={0.1}
        min={0.0}
        value={details.renderAsKilograms ? details.pounds / 2.2 : details.pounds}
        onChange={e => {
          const value = Number(e.target.value);
          if (details.renderAsKilograms) {
            onSetPackageDetails(({ ...details, pounds: value * 2.2, kilograms: value }));
          } else {
            onSetPackageDetails(({ ...details, pounds: value, kilograms: value / 2.2 }));
          }
        }}
      />
    </Form.Group>

    {
      hideInsurance ||
      <Form.Group>
        <Form.Label><strong>Insurance Coverage (USD) (Optional)</strong></Form.Label>
        <Form.Control
          type="number"
          placeholder="$$$"
          step={10.0}
          min={0.0}
          max={MAX_INSURANCE}
          value={Number(details.monetaryValue)}
          onChange={e =>
            onSetPackageDetails(({ ...details, monetaryValue: Number(e.target.value) }))
          }
        />
      </Form.Group>
    }
  </>);
}
