import { Button } from "react-bootstrap";
import { ApolloError, RefetchQueriesInclude } from "@apollo/client";
import { Order, OrderServiceTier } from "../apollo/queries/order";
import Modal, { useModalProperties } from "../components/Modal";
import PaymentForm from "../forms/PaymentForm";

type PayNowButtonProperties = {
  buttonTitle: string;
  order: Order;
  shippingTo: "WAREHOUSE" | "DESTINATION";
  refetchQueries: RefetchQueriesInclude;
  onSetServiceTier: (tier: OrderServiceTier) => void;
  onError: (error: string | ApolloError | JSX.Element) => void;
}

export default function PayNowButton({ buttonTitle, order, shippingTo, refetchQueries, onError, onSetServiceTier }: PayNowButtonProperties): JSX.Element {
  const [paymentModalProperties, setPaymentModalProperties] = useModalProperties({
    type: "info",
    title: "Confirm Payment Options",
    body: <PaymentForm
      order={order}
      shippingTo={shippingTo}
      refetchQueries={refetchQueries}
      onError={onError}
      onSetServiceTier={onSetServiceTier}
      onClose={() => setPaymentModalProperties(({ show: false }))}
    />
  });

  return (<>
    <Modal {...paymentModalProperties} />
    <Button
      className="mx-1 mt-3"
      onClick={() => setPaymentModalProperties(({ show: true }))}
    >
      {buttonTitle}
    </Button>
  </>);
}