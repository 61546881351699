import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SpinnerButton from "../components/SpinnerButton";
import { OrderPackageDetails } from "../apollo/queries/order";
import PackageDetailsFormInput from "../components/PackageDetailsInput";

type PackageFormProperties = {
  package: OrderPackageDetails;
  onClose: () => void;
  onSetPackageDetails: (details: OrderPackageDetails) => Promise<void>;
}

export default function PackageDetailsForm({ package: details, onClose, onSetPackageDetails }: PackageFormProperties): JSX.Element {
  const [packageDetails, setPackageDetails] = useState(details);
  const [loading, setLoading] = useState(false);
  const canSubmit = packageDetails.heightInches
    && packageDetails.lengthInches
    && packageDetails.widthInches
    && packageDetails.pounds;

  return (
    <Form onSubmit={e => {
      e.preventDefault();
      setLoading(true);
      onSetPackageDetails(packageDetails).finally(() => setLoading(false));
    }}>
      <Container>
        <Row>
          <Col>
            <PackageDetailsFormInput
              package={packageDetails}
              onSetPackageDetails={setPackageDetails}
            />
          </Col>
        </Row>
      </Container>


      <div className="d-flex justify-content-end">
        <SpinnerButton
          type="submit"
          spin={loading}
          disabled={!canSubmit || loading}
          className="mt-3 mx-1"
        >
          <strong>{details.pounds ? "Update" : "Set"}</strong>
        </SpinnerButton>
        <Button
          variant="secondary"
          className="mt-3 mx-1"
          type="button"
          disabled={loading}
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Form>
  );
}