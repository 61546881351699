import { MutationResult, useMutation } from '@apollo/client';
import { S3PresignedPostCreateResult, S3_PRESIGNED_POST } from './queries';
import { StatusCodes } from "http-status-codes";
import { useState } from 'react';
import { ErrorEventHandler } from '../../types';
import { FileTooLargeError } from './errors';

type Upload = (classification: string, file: File, maxBytes?: number) => Promise<string>;

export type S3UploaderProperties = {
  onError?: ErrorEventHandler;
}

export default function useS3Uploader({ onError }: S3UploaderProperties = {}): [Upload, MutationResult<S3PresignedPostCreateResult>] {
  const [uploading, setUploading] = useState(false);
  const [s3, result] = useMutation<S3PresignedPostCreateResult>(S3_PRESIGNED_POST,
    {
      onError,
    }
  );
  const upload = async (classification: string, file: File, maxBytes?: number): Promise<string> => {
    if (maxBytes && file.size > maxBytes) {
      throw new FileTooLargeError(file.size, maxBytes);
    }

    setUploading(true);
    try {
      const { data } = await s3({
        variables: {
          classification,
          filename: file.name,
        }
      });

      if (data === null || data === undefined) {
        return "";
      }

      const presigned = JSON.parse(data.s3PresignedPostCreate.data);
      return uploadBlob(file, presigned.url, presigned.fields);
    }
    finally {
      setUploading(false);
    }
  };

  return [
    upload,
    {
      ...result,
      loading: result.loading || uploading,
    },
  ];
}

function uploadBlob(file: File, url: string, fields: Record<string, string>): string {
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", file);

  const request = new XMLHttpRequest();
  request.open("POST", url, false);
  request.send(formData);
  if (request.status >= StatusCodes.OK && request.status < 300) {
    return `${url}/${fields["key"]}`;
  }
  throw JSON.stringify({
    "status": request.status,
    "statusText": request.statusText,
    "text": request.responseText,
  });
}