import { ApolloError, useMutation } from "@apollo/client";
import { useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { USER_FORGOT_PASSWORD } from "../apollo/queries/user";
import { useNavigate } from "react-router-dom";
import { URLS } from "../utils/constants";
import Modal, { useModalProperties } from "../components/Modal";
import validateEmail from "../utils/validateEmail";
import SpinnerButton from "../components/SpinnerButton";

export type SendPasswordResetFormProperties = {
  onError: (error: string | ApolloError | JSX.Element) => void;
}

export default function SendPasswordResetForm({ onError }: SendPasswordResetFormProperties): JSX.Element {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [modalProperties, setModalProperties] = useModalProperties({
    type: "info",
    title: "Reset Link Sent",
    buttonText: "Home",
    onHide: () => navigate(URLS.LANDING),
    onPrimaryButtonClick: () => navigate(URLS.LANDING),
  })

  const [sendLink, { loading }] = useMutation(USER_FORGOT_PASSWORD);

  const handleSendResetLink = () => {
    sendLink({
      variables: { email },
      onCompleted() {
        setModalProperties(({
          body: <>
            If a matching account was found, a Password Reset Link will be sent to&nbsp;
            <strong>{email}</strong>.
          </>,
          show: true,
        }));
      },
      onError,
    })
  };

  return (
    <>
      <Modal {...modalProperties} />
      <Container>
        <Row className="justify-content-md-center">
          <Col md={6}>
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleSendResetLink();
              }}
              className="text-center"
            >
              <Form.Group>
                <Form.Label><strong className="h4">Email</strong></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="me@example.com"
                  value={email}
                  onChange={(e) => {
                    const email = e.target.value;
                    validateEmail(email, setEmailError);
                    setEmail(email);
                  }}
                  className="mb-4"
                />
                <SpinnerButton
                  type="submit"
                  spin={loading}
                  disabled={loading || email.length < 1 || emailError.length > 0}
                >
                  <strong>Send Reset Link</strong>
                </SpinnerButton>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}