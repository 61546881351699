import React from 'react';
import ReactDOMServer from 'react-dom/server';

export default function createObjectURLFromSVG(icon: React.ReactNode): string {
  const svgData = ReactDOMServer.renderToString(icon);

  const blobContent = `
    <?xml version="1.0" encoding="UTF-8"?>
    ${svgData}
  `.trim();

  const blob = new Blob([blobContent], { type: 'image/svg+xml' });
  const url = URL.createObjectURL(blob);

  return url;
}