import { ApolloError, FetchResult, MutationFunctionOptions, gql, useMutation } from "@apollo/client";
import { USER_PUBLIC_FRAGMENT, UserPublic } from "./user";
import { PAGE_INFO_FRAGMENT, PageInfo } from "./pageinfo";

export const DEFAULT_ORDER_OWNER_ADDRESS: OrderOwnerAddress = {
  name: "",
  attentionName: "",
  addressLine: "[]",
  city: "",
  stateProvinceCode: "",
  postalCode: "",
  countryCode: "US",
  residential: true,
}

export const DEFAULT_ORDER_PACKAGE_DETAILS: OrderPackageDetails = {
  lengthCentimeters: 0,
  lengthInches: 0,
  heightCentimeters: 0,
  heightInches: 0,
  widthCentimeters: 0,
  widthInches: 0,
  kilograms: 0,
  pounds: 0,
  renderAsCentimeters: false,
  renderAsKilograms: false,
  monetaryValueCurrency: "USD",
  monetaryValue: 0,
}

export const DEFAULT_ORDER: Order = {
  slug: "",
  title: "",
  description: "",
  imageUrls: [],
  warehouseImageUrls: [],
  warehouseDescription: "",
  warehouseShelf: "",
  linkable: true,
  public: true,
  editable: true,
  isFinal: false,
  cancelable: false,
  canceled: false,
  completed: false,
  status: { slug: "", name: "" },
  address: DEFAULT_ORDER_OWNER_ADDRESS,
  package: DEFAULT_ORDER_PACKAGE_DETAILS,
}

export type OrderPaymentStatus = {
  slug: string;
  paid: boolean;
  paymentUrl: string;
  receiptUrl: string;
}

export type OrderPaymentStatusResult = {
  orderPaymentStatus: OrderPaymentStatus;
}

export type OrderStatus = {
  slug: string;
  name: string;
}

export type OrderOwnerAddress = {
  name: string;
  attentionName: string;
  addressLine: string;
  city: string;
  stateProvinceCode: string;
  postalCode: string;
  countryCode: string;
  residential: boolean;
}

export type OrderServiceTier = {
  slug: string;
  name: string;
  description: string;
  price: string;
  priceCurrency: string;
  providesDescriptionService: boolean;
  providesImageService: boolean;
}

export type OrderTimeInTransit = {
  dislaimer: string;
  serviceDescription: string;
  pickupTime: Date;
  arrivalTime: Date;
  lastCustomerDropoffTime: Date;
}

export type OrderShippingRate = {
  shippingTo: string;
  shippingService: string;
  alerts: string[];
  estimatedTimeInTransit: OrderTimeInTransit;
  cost: string;
  costCurrency: string;
}

export type OrderShippingRatesResult = {
  orderShippingRates: OrderShippingRate[];
}

export type OrderPackageDetails = {
  lengthInches: number;
  heightInches: number;
  widthInches: number;
  pounds: number;

  lengthCentimeters: number;
  heightCentimeters: number;
  widthCentimeters: number;
  kilograms: number;

  renderAsCentimeters: boolean;
  renderAsKilograms: boolean;

  monetaryValueCurrency: string;
  monetaryValue: number;
}

export type OrderLabel = {
  order?: {
    slug?: string;
    warehouseShelf?: string;
  }
  createdDate?: Date;
  modifiedDate?: Date;
  inboundTrackingNumber: string;
  inboundImageFormat: string;
  inboundImageBase64: string;
  outboundTrackingNumber?: string;
  outboundImageFormat?: string;
  outboundImageBase64?: string;
}

// This mirrors data exactly as it comes from GQL. Adding more mirrored fields here
// will result in them auto-populating when we fetch order information.
export type Order = {
  slug: string;
  owner?: UserPublic;
  associatedOrder?: Order;
  title?: string;
  viewCount?: number;
  description?: string;
  imageUrls?: string[];
  warehouseImageUrls?: string[];
  warehouseDescription?: string;
  warehouseShelf?: string;
  createdDate?: string;
  modifiedDate?: string;
  linkable?: boolean;
  public?: boolean;
  editable?: boolean;
  received?: boolean;
  approved?: boolean;
  rejected?: boolean;
  isFinal?: boolean;
  cancelable?: boolean;
  canceled?: boolean;
  completed?: boolean;
  status?: OrderStatus;
  serviceTier?: OrderServiceTier;
  address?: OrderOwnerAddress;
  package?: OrderPackageDetails;
  returnPackage?: OrderPackageDetails;
  orderlabel?: OrderLabel;
  warehousePaymentStatus?: OrderPaymentStatus;
  destinationPaymentStatus?: OrderPaymentStatus;
}

export type OrderResult = {
  orders: {
    edges: {
      node: Order;
    }[];
    pageInfo: PageInfo;
  };
}

export type OrderCreateResult = {
  orderCreate: {
    order: Order;
  }
}

export type OrderUpdateResult = {
  orderUpdate: {
    order: Order;
  }
}

export type OrderCancelResult = {
  orderCancel: {
    order: Order;
  }
}

export type OrderApproveOrRejectResult = {
  orderApproveOrReject: {
    order: Order;
  }
}

export type OrderLabelForDestinationResult = {
  orderLabelsForDestination: {
    edges: {
      node: OrderLabel;
    }[];
    pageInfo: PageInfo;
  }
}

const ORDER_TIME_IN_TRANSIT = gql`
fragment OrderTimeInTransit on OrderTimeInTransitType {
  disclaimer
  serviceDescription
  pickupTime
  arrivalTime
  lastCustomerDropoffTime
}
`;

const ORDER_SHIPPING_RATE_FRAGMENT = gql`
${ORDER_TIME_IN_TRANSIT}

fragment OrderShippingRate on OrderRatingType {
  shippingTo
  shippingService
  alerts
  estimatedTimeInTransit {
    ...OrderTimeInTransit
  }
  cost
  costCurrency
}
`;

const ORDER_BASICS_FRAGMENT = gql`
fragment OrderBasics on OrderType {
  slug
  title
  description
  viewCount
  imageUrls
  warehouseImageUrls
  warehouseDescription
  warehouseShelf
}
`;

const ORDER_STATE_FRAGMENT = gql`
fragment OrderState on OrderType {
  createdDate
  modifiedDate
  linkable
  public
  editable
  received
  approved
  rejected
  isFinal
  cancelable
  canceled
  completed
  status {
    slug
    name
  }
}
`;

const ORDER_SERVICE_TIER_FRAGMENT = gql`
fragment OrderServiceTier on OrderServiceTierType {
  slug
  name
  description
  price
  priceCurrency
  providesDescriptionService
  providesImageService
}
`;

const ORDER_OWNER_ADDRESS_FRAGMENT = gql`
fragment OrderOwnerAddress on OrderOwnerAddressType {
  name
  attentionName
  addressLine
  city
  stateProvinceCode
  postalCode
  countryCode
  residential
}
`;

const ORDER_PACKAGE_DETAILS_FRAGMENT = gql`
fragment OrderPackageDetails on OrderPackageDetailsType {
  lengthInches
  heightInches
  widthInches
  pounds
  lengthCentimeters
  heightCentimeters
  widthCentimeters
  kilograms
  renderAsCentimeters
  renderAsKilograms
  monetaryValueCurrency
  monetaryValue
}
`;

const ORDER_LABEL_FRAGMENT = gql`
fragment OrderLabel on OrderLabelType {
  order {
    slug
    warehouseShelf
  }
  createdDate
  modifiedDate
  inboundTrackingNumber
  inboundImageFormat
  inboundImageBase64
  outboundTrackingNumber
  outboundImageFormat
  outboundImageBase64
}
`;

const ORDER_PAYMENT_STATUS_FRAGMENT = gql`
fragment OrderPaymentStatus on OrderPaymentStatusType {
  slug
  paid
  paymentUrl
  receiptUrl
}
`;

const ORDER_FRAGMENT = gql`
${ORDER_BASICS_FRAGMENT}
${ORDER_LABEL_FRAGMENT}
${ORDER_OWNER_ADDRESS_FRAGMENT}
${USER_PUBLIC_FRAGMENT}
${ORDER_PACKAGE_DETAILS_FRAGMENT}
${ORDER_PAYMENT_STATUS_FRAGMENT}
${ORDER_STATE_FRAGMENT}
${ORDER_SERVICE_TIER_FRAGMENT}

fragment OrderDetails on OrderType {
  ...OrderBasics
  ...OrderState
  owner {
    ...UserPublic
  }
  serviceTier {
    ...OrderServiceTier
  }
  address {
    ...OrderOwnerAddress
  }
  package {
    ...OrderPackageDetails
  }
  returnPackage {
    ...OrderPackageDetails
  }
  orderlabel {
    ...OrderLabel
  }
  warehousePaymentStatus {
    ...OrderPaymentStatus
  }
  destinationPaymentStatus {
    ...OrderPaymentStatus
  }
}

fragment Order on OrderType {
  ...OrderDetails
  associatedOrder {
    ...OrderDetails
    associatedOrder {
      slug
    }
  }
}
`;

export const ORDERS = gql`
${ORDER_FRAGMENT}
${PAGE_INFO_FRAGMENT}

query Orders(
  $first: Int
  $last: Int
  $before: String
  $after: String
  $search: String
  $slug: String
  $owner: UUID
  $canceled: Boolean
  $completed: Boolean
  $linkable: Boolean
  $public: Boolean
  $associatedOrderIsNull: Boolean
  $ordering: String
) {
  orders(
    first: $first
    last: $last
    before: $before
    after: $after
    search: $search
    slug: $slug
    owner_Uuid: $owner
    canceled: $canceled
    completed: $completed
    linkable: $linkable
    public: $public
    associatedOrder_Isnull: $associatedOrderIsNull
    ordering: $ordering
  ) {
    edges {
      node {
        ...Order
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
`;

export const ASSOCIATED_ORDERS = gql`
${ORDER_FRAGMENT}
${PAGE_INFO_FRAGMENT}

query AssociatedOrders(
  $first: Int
  $last: Int
  $before: String
  $after: String
  $associatedOrderSlug: String
) {
  orders(
    first: $first
    last: $last
    before: $before
    after: $after
    associatedOrder_Slug: $associatedOrderSlug
  ) {
    edges {
      node {
        ...Order
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
`;

export const ORDER_CREATE = gql`
${ORDER_FRAGMENT}

mutation OrderCreate(
  $title: String!
  $description: String!
  $imageUrls: [String]
  $linkable: Boolean
  $public: Boolean
  $associatedOrderSlug: String
) {
  orderCreate(
    title: $title
    description: $description
    imageUrls: $imageUrls
    linkable: $linkable
    public: $public
    associatedOrderSlug: $associatedOrderSlug
  ) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_UPDATE = gql`
${ORDER_FRAGMENT}

mutation OrderUpdate(
  $slug: String!
  $title: String
  $description: String
  $serviceTier: String
  $imageUrls: [String]
  $linkable: Boolean
  $public: Boolean
  $associatedOrderSlug: String
) {
  orderUpdate(
    slug: $slug
    title: $title
    description: $description
    serviceTier: $serviceTier
    imageUrls: $imageUrls
    linkable: $linkable
    public: $public
    associatedOrderSlug: $associatedOrderSlug
  ) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_REPORT_LISTING = gql`
mutation ReportListing(
  $slug: String!
  $category: String!
  $comment: String
) {
  orderReportListing(
    slug: $slug
    category: $category
    comment: $comment
  ) {
    ok
  }
}
`;

export const ORDER_MODERATE_LISTING = gql`
mutation ModerateListing(
  $slug: String!
  $remove: Boolean!
  $reason: String!
) {
  orderModerateListing(
    slug: $slug
    remove: $remove
    reason: $reason
  ) {
    removed
    why
  }
}
`;

export const ORDER_APPROVE = gql`
${ORDER_FRAGMENT}

mutation OrderApprove($slug: String!) {
  orderApproveOrReject(slug: $slug, action: APPROVE) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_REJECT = gql`
${ORDER_FRAGMENT}

mutation OrderReject($slug: String!) {
  orderApproveOrReject(slug: $slug, action: REJECT) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_WAREHOUSE_UPDATE = gql`
${ORDER_FRAGMENT}

mutation OrderWarehouseUpdate(
  $slug: String!
  $warehouseShelf: String!
  $warehouseDescription: String
  $warehouseImageUrls: [String]
) {
  orderWarehouseUpdate(
    slug: $slug
    warehouseShelf: $warehouseShelf
    warehouseDescription: $warehouseDescription
    warehouseImageUrls: $warehouseImageUrls
  ) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_COMPLETE = gql`
mutation OrderComplete($slug: String!) {
  orderComplete(slug: $slug) {
    ok
  }
}
`;

export const ORDER_LABELS_FOR_DESTINATION = gql`
${ORDER_LABEL_FRAGMENT}
${PAGE_INFO_FRAGMENT}

query OrderLabelsForDestination(
  $first: Int
  $last: Int
  $before: String
  $after: String
) {
  orderLabelsForDestination(
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    edges {
      node {
        ...OrderLabel
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
`;

export const ORDER_SET_ADDRESS = gql`
${ORDER_FRAGMENT}

mutation OrderSetAddress(
  $slug: String!
  $name: String!
  $attention: String
  $addressLine: [String!]!
  $city: String!
  $stateProvinceCode: String!
  $postalCode: String!
  $countryCode: String!
  $residential: Boolean!
) {
  orderSetAddress(
    slug: $slug
    name: $name
    attention: $attention
    addressLine: $addressLine
    city: $city
    stateProvinceCode: $stateProvinceCode
    postalCode: $postalCode
    countryCode: $countryCode
    residential: $residential
  ) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_SET_PACKAGE_DETAILS = gql`
${ORDER_FRAGMENT}

mutation OrderSetPackageDetails(
  $slug: String!
  $lengthInches: Float!
  $heightInches: Float!
  $widthInches: Float!
  $pounds: Float!
  $renderAsCentimeters: Boolean!
  $renderAsKilograms: Boolean!
  $monetaryValueCurrency: String!
  $monetaryValue: Float!
  $forReturn: Boolean!
) {
  orderSetPackageDetails(
    slug: $slug
    lengthInches: $lengthInches
    heightInches: $heightInches
    widthInches: $widthInches
    pounds: $pounds
    renderAsCentimeters: $renderAsCentimeters
    renderAsKilograms: $renderAsKilograms
    monetaryValueCurrency: $monetaryValueCurrency
    monetaryValue: $monetaryValue
    forReturn: $forReturn
  ) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_SET_SERVICE_TIER = gql`
${ORDER_FRAGMENT}

mutation OrderSetServiceTier($slug: String!, $serviceTier: String!) {
  orderSetServiceTier(slug: $slug, serviceTier: $serviceTier) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_SHIPPING_RATES = gql`
${ORDER_SHIPPING_RATE_FRAGMENT}

query OrderShippingRates(
  $slug: String!
  $shippingTo: ShippingTo!
) {
  orderShippingRates(slug: $slug, shippingTo: $shippingTo) {
    ...OrderShippingRate
  }
}
`;

export const ORDER_CHECKOUT_CREATE = gql`
mutation OrderCheckoutCreate(
  $slug: String!
  $shippingTo: ShippingTo!
  $shippingService: ShippingService!
  $successUrl: String!
  $cancelUrl: String!
  $discountCode: String
) {
  checkoutCreate(
    orderSlug: $slug
    shippingTo: $shippingTo
    shippingService: $shippingService
    successUrl: $successUrl
    cancelUrl: $cancelUrl
    discountSlug: $discountCode
  ) {
    statusSlug
  }
}
`;

export const ORDER_CANCEL = gql`
${ORDER_FRAGMENT}

mutation OrderCancel($slug: String!) {
  orderCancel(slug: $slug) {
    order {
      ...Order
    }
  }
}
`;

export const ORDER_VIEW_COUNT_INCREMENT = gql`
${ORDER_FRAGMENT}

mutation OrderViewCountIncrement($slug: String!) {
  orderViewCountIncrement(slug: $slug) {
    ok
  }
}
`;

export const ORDER_SERVICE_TIERS = gql`
${ORDER_SERVICE_TIER_FRAGMENT}

query ServiceTiers {
  serviceTiers {
    edges {
      node {
        ...OrderServiceTier
      }
    }
  }
}
`;

export const ORDER_PAYMENT_STATUS = gql`
${ORDER_PAYMENT_STATUS_FRAGMENT}

query OrderPaymentStatus($slug: String!) {
  orderPaymentStatus(slug: $slug) {
    ...OrderPaymentStatus
  }
}
`;

export type OrderManagerProperties = {
  order: Order;
  onError: (error: string | ApolloError | JSX.Element) => void;
  onCreateOrderCompleted?: (order: Order) => void;
  onUpdateOrderCompleted?: (order: Order) => void;
  onCancelOrderCompleted?: (order: Order) => void;
  onApproveOrderCompleted?: (order: Order) => void;
  onRejectOrderCompleted?: (order: Order) => void;
}

export type OrderManager = {
  loading: boolean;
  bidrectional: boolean;
  includesMe: boolean;
  createOrder: (options?: MutationFunctionOptions<OrderCreateResult>) => Promise<FetchResult<OrderCreateResult>>;
  updateOrder: (options?: MutationFunctionOptions<OrderUpdateResult>) => Promise<FetchResult<OrderUpdateResult>>;
  cancelOrder: (options?: MutationFunctionOptions<OrderCancelResult>) => Promise<FetchResult<OrderCancelResult>>;
  approveOrder: (options?: MutationFunctionOptions<OrderApproveOrRejectResult>) => Promise<FetchResult<OrderApproveOrRejectResult>>;
  rejectOrder: (options?: MutationFunctionOptions<OrderApproveOrRejectResult>) => Promise<FetchResult<OrderApproveOrRejectResult>>;
  incrementOrderViewCount: (options?: MutationFunctionOptions<any>) => Promise<FetchResult<any>>;
}

export function useOrderManager(
  {
    order,
    onError,
    onCreateOrderCompleted,
    onUpdateOrderCompleted,
    onCancelOrderCompleted,
    onApproveOrderCompleted,
    onRejectOrderCompleted,
  }: OrderManagerProperties
): OrderManager {
  const [createOrder, { loading: creatingOrder }] = useMutation<OrderCreateResult>(ORDER_CREATE, {
    refetchQueries: [ORDERS],
    onCompleted: ({ orderCreate: { order } }) => onCreateOrderCompleted && onCreateOrderCompleted(order),
    onError,
  });
  const [updateOrder, { loading: updatingOrder }] = useMutation<OrderUpdateResult>(ORDER_UPDATE, {
    refetchQueries: [ORDERS],
    onCompleted: ({ orderUpdate: { order } }) => onUpdateOrderCompleted && onUpdateOrderCompleted(order),
    onError,
  });
  const [cancelOrder, { loading: cancelingOrder }] = useMutation<OrderCancelResult>(ORDER_CANCEL, {
    refetchQueries: [ORDERS],
    onCompleted: ({ orderCancel: { order } }) => onCancelOrderCompleted && onCancelOrderCompleted(order),
    onError,
  });
  const [approveOrder, { loading: approvingOrder }] = useMutation<OrderApproveOrRejectResult>(ORDER_APPROVE, {
    refetchQueries: [ORDERS],
    variables: { slug: order.slug },
    onCompleted: ({ orderApproveOrReject: { order } }) => onApproveOrderCompleted && onApproveOrderCompleted(order),
    onError,
  });
  const [rejectOrder, { loading: rejectingOrder }] = useMutation<OrderApproveOrRejectResult>(ORDER_REJECT, {
    refetchQueries: [ORDERS],
    variables: { slug: order.slug },
    onCompleted: ({ orderApproveOrReject: { order } }) => onRejectOrderCompleted && onRejectOrderCompleted(order),
    onError,
  });
  const [incrementOrderViewCount, { loading: incrementingOrderViewCount }] = useMutation(ORDER_VIEW_COUNT_INCREMENT, {
    onError,
  });

  const bidrectional = (
    Boolean(order.associatedOrder) &&
    Boolean(order.associatedOrder?.associatedOrder) &&
    order.associatedOrder?.associatedOrder?.slug === order.slug
  );

  return {
    loading: (
      creatingOrder ||
      updatingOrder ||
      cancelingOrder ||
      approvingOrder ||
      rejectingOrder ||
      incrementingOrderViewCount
    ),
    bidrectional,
    includesMe: (
      bidrectional && (
        Boolean(order.owner?.isMe) ||
        Boolean(order.associatedOrder?.owner?.isMe)
      )
    ),
    createOrder,
    updateOrder,
    cancelOrder,
    approveOrder,
    rejectOrder,
    incrementOrderViewCount,
  }
}
