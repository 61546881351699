import { useState } from 'react';
import { Button } from "react-bootstrap";
import { FileEarmark, X } from "react-bootstrap-icons";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './ThumbnailImage.css'
import { filenameAsThumbnail } from './useFilePicker';

type ThumbnailImageProperties = {
  src?: string;
  clearable?: boolean;
  onClearClick?: (url: string) => void;
}

type ThumbnailImagesProperties = {
  imageUrls: string[];
  clearable?: boolean;
  onClearClick?: (url: string, index: number) => void;
}

export default function ThumbnailImage({ src, clearable, onClearClick }: ThumbnailImageProperties): JSX.Element {
  if (!src) {
    return (
      <FileEarmark className="img-thumbnail" />
    );
  }

  return (
    <>
      <img
        className="img-thumbnail"
        src={filenameAsThumbnail(src)}
        alt="thumbnail"
        onError={e => e.currentTarget.src = src}
      />
      {clearable && (
        <Button
          className="close"
          onClick={e => {
            e.stopPropagation();
            onClearClick && onClearClick(src);
          }}
        >
          <X />
        </Button>
      )}
    </>
  )
}

export function ThumbnailImages({ imageUrls, clearable, onClearClick }: ThumbnailImagesProperties): JSX.Element {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="thumbnail">
      {imageUrls.map((url, idx) =>
        <span
          className="thumbnail"
          key={`img-${idx}`}
          onClick={() => openLightbox(idx)}
        >
          <ThumbnailImage
            src={url}
            clearable={clearable}
            onClearClick={src => onClearClick && onClearClick(src, idx)}
          />
        </span>
      )}

      {
        isOpen &&
        <Lightbox
          mainSrc={imageUrls[photoIndex]}
          nextSrc={imageUrls[(photoIndex + 1) % imageUrls.length]}
          prevSrc={imageUrls[(photoIndex + imageUrls.length - 1) % imageUrls.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imageUrls.length - 1) % imageUrls.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageUrls.length)
          }
        />
      }
    </div>
  );
}

