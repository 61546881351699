import { Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Order } from "../../apollo/queries/order";
import Modal from "../Modal";
import Logo from "../Logo";
import { PageInfo } from "../../apollo/queries/pageinfo";

type OrderListProperties = {
  title: string;
  show: boolean;
  loading: boolean;
  orders: Order[];
  pageInfo: PageInfo;
  onClose?: () => void;
  onSelect?: (order: Order) => void;
  onPreviousPage?: (cursor: String) => void;
  onNextPage?: (cursor: String) => void;
}

type OrderTableProperties = {
  show?: boolean;
  loading: boolean;
  orders: Order[];
  pageInfo: PageInfo;
  onClose?: () => void;
  onSelect?: (order: Order) => void;
  onPreviousPage?: (cursor: String) => void;
  onNextPage?: (cursor: String) => void;
}

export function OrderListTable({ show, orders, loading, pageInfo, onClose, onSelect, onPreviousPage, onNextPage }: OrderTableProperties) {
  if (!show) {
    return (<></>);
  }

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Fetching your orders...</strong></p>
      </div>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td><strong>Title</strong></td>
                <td><strong>Status</strong></td>
              </tr>
            </thead>
            <tbody>
              <>
                {orders.map((order, idx) => (
                  <OverlayTrigger key={idx} placement="bottom-start" overlay={<Tooltip>{order.description}</Tooltip>}>
                    <tr
                      role="button"
                      onClick={() => onSelect && onSelect(order)}
                    >
                      <td>{order.title}</td>
                      <td>{order.status?.name}</td>
                    </tr>
                  </OverlayTrigger>
                ))}
              </>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="secondary"
            disabled={!pageInfo.hasPreviousPage}
            onClick={() => onPreviousPage && onPreviousPage(pageInfo.startCursor)}
          >
            {"<<"}
          </Button>
          <Button variant="secondary"
            className="mx-2"
            disabled={!pageInfo.hasNextPage}
            onClick={() => onNextPage && onNextPage(pageInfo.endCursor)}
          >
            {">>"}
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button variant="secondary" onClick={onClose}>Close</Button>
        </Col>
      </Row>
    </Container>
  );
}

export default function OrderListModal({ title, show, loading, orders, pageInfo, onClose, onSelect, onPreviousPage, onNextPage }: OrderListProperties) {
  return Modal({
    type: "info",
    title,
    show,
    body: show
      ? <OrderListTable
        show={show}
        loading={loading}
        orders={orders}
        pageInfo={pageInfo}
        onClose={onClose}
        onSelect={onSelect}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
      : <></>,
    onHide: onClose,
  });
}