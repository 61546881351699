export const URLS = {
  LANDING: "/",
  ABOUT: "/about",
  CONTACT: "/contact",
  ORDER_EDIT: "/orders/edit",
  ORDER_WAREHOUSE: "/orders/warehouse",
  ORDER_WAREHOUSE_LABELS: "/orders/warehouse/labels",
  TOS: "/tos",
  STORE: "/store",
  USER_LOGIN: "/user/login",
  USER_SEND_PASSWORD_RESET: "/user/send_password_reset",
  USER_PASSWORD_RESET: "/user/password_reset",
  USER_EMAIL_VERIFY: "/user/email_verify",
  USER_PROFILE: "/user/profile",
  USER_SIGNUP: "/user/signup",
  USER_SIGNUP_SUCCESS: "/user/signup/success",
}