import './App.css';
import 'react-bootstrap';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthenticationForm from './forms/AuthenticationForm';
import Footer from './components/Footer';
import Header from './components/Header';
import Landing from './components/Landing';
import AboutUs from './components/AboutUs';
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import logError from './utils/logError';
import { ApolloClient, ApolloError, NormalizedCacheObject } from '@apollo/client';
import ProfileInfo from './components/ProfileInfo';
import { URLS } from './utils/constants';
import PasswordResetForm from './forms/PasswordResetForm';
import SendPasswordResetForm from './forms/SendPasswordResetForm';
import ContactInfo from './components/ContactInfo';
import Store from './components/Store';
import EmailVerified from './components/EmailVerified';
import NavigableModal from './components/NavigableModal';
import { CallbackError } from './types';
import { OrderForm, WarehouseIntakeForm, WarehouseShippingform } from './components/order';

<style>
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,400;0,700;1,400&family=Lexend+Deca:wght@200;300;400;500;600;700&family=Patua+One&display=swap');
</style>

function Fallback(props: FallbackProps) {
  const { error } = props;

  return (
    <div role="alert">
      <p>Oh no! Something went wrong!</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>;
}

export default function App({ client }: AppProps) {
  const [titles, setTitles] = useState<string[]>([]);
  const [messages, setMessages] = useState<(string | JSX.Element)[]>([]);
  const [errorMessages, setErrorMessages] = useState<(string | JSX.Element)[]>([]);
  const [errors, setErrors] = useState<(string | ApolloError | CallbackError | JSX.Element)[]>([]);

  const onInfo = (title: string, message: string | JSX.Element) => {
    setTitles(prev => ([...prev, title]));
    setMessages(prev => ([...prev, message]));
  }
  const onError = (error: string | Error | ApolloError | CallbackError | JSX.Element) => {
    if (error) {
      console.error(error);
      setErrors(prev => ([...prev, error]));
      if (error instanceof ApolloError) {
        const parsed = JSON.parse(error.message);
        setErrorMessages(prev => ([...prev, parsed.error]));
      } else if (error instanceof CallbackError) {
        setErrorMessages(prev => ([...prev, error.message]));
      } else if (error instanceof Error) {
        setErrorMessages(prev => ([...prev, error.message]));
      } else {
        setErrorMessages(prev => ([...prev, error]));
      }
    }
  }

  // Google Ad Sense. Mounts to Head 
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9845135574524675";
    script.async = true; // Loads script asynchronously
    script.crossOrigin = "anonymous"; // Set cross-origin attribute if needed

    // Append script to document head
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="App flex bg-light">
      <ErrorBoundary FallbackComponent={Fallback} onError={(error, info) => logError(client, error, info)}>
        <NavigableModal
          type='info'
          title={titles[0]}
          message={messages[0]}
          show={Boolean(titles.length)}
          hasNext={titles.length > 1}
          onCloseClick={() => {
            setTitles([]);
            setMessages([]);
          }}
          onNextClick={() => {
            setTitles(prev => ([...prev.slice(1)]));
            setMessages(prev => ([...prev.slice(1)]));
          }}
        />

        <NavigableModal
          type="alert"
          title="Error"
          message={errorMessages[0]}
          show={Boolean(errorMessages.length)}
          hasNext={errorMessages.length > 1}
          onCloseClick={() => {
            errors.forEach(error => {
              if (error instanceof CallbackError) {
                error.callback && error.callback();
              }
            })
            setErrors([]);
            setErrorMessages([]);
          }}
          onNextClick={() => {
            setErrors(prev => ([...prev.slice(1)]));
            setErrorMessages(prev => ([...prev.slice(1)]));
          }}
        />

        <Router>
          <Header onError={onError} />
          <Routes>
            <Route path={URLS.LANDING} element={<Landing />} />
            <Route path={URLS.ABOUT} element={<AboutUs />} />
            <Route path={URLS.CONTACT} element={<ContactInfo />} />
            <Route path={URLS.STORE} element={<Store />} />
            <Route path={URLS.ORDER_EDIT} element={<OrderForm onInfo={onInfo} onError={onError} />} />
            <Route path={URLS.ORDER_WAREHOUSE} element={<WarehouseIntakeForm onError={onError} />} />
            <Route path={URLS.ORDER_WAREHOUSE_LABELS} element={<WarehouseShippingform onError={onError} />} />
            <Route path={URLS.USER_SEND_PASSWORD_RESET} element={<SendPasswordResetForm onError={onError} />} />
            <Route path={URLS.USER_PASSWORD_RESET} element={<PasswordResetForm />} />
            <Route path={URLS.USER_EMAIL_VERIFY} element={<EmailVerified />} />
            <Route path={URLS.USER_LOGIN} element={
              <AuthenticationForm
                type="login"
                onError={onError}
              />}
            />
            <Route path={URLS.USER_SIGNUP} element={
              <AuthenticationForm
                type="signup"
                onInfo={onInfo}
                onError={onError}
              />}
            />
            <Route path={URLS.USER_PROFILE} element={<ProfileInfo onError={onError} onInfo={onInfo} />} />
          </Routes>
          <Footer />
        </Router>
      </ErrorBoundary>
    </div>
  );
}
