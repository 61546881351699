import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { useMutation, useQuery } from "@apollo/client";
import { DEFAULT_USER, UserResult, USER_GET_ME_QUERY, USER_UPDATE_MUTATION, User } from "../apollo/queries/user";
import Logo from "./Logo";
import ProfileForm from '../forms/ProfileForm';
import { useCallback, useState } from 'react';
import useS3Uploader from '../apollo/s3/useS3';
import { To, createSearchParams, useHref, useNavigate } from 'react-router-dom';
import { URLS } from '../utils/constants';
import ShareButton from './ShareButton';
import { ErrorEventHandler, InfoEventHandler } from '../types';

const TXT_LINK_COPY = "Copy Link";
const TXT_LINK_COPIED = "Link Copied";

type UserProfileProps = {
  user: User;
}

export type ProfileInfoProperties = {
  onError: ErrorEventHandler;
  onInfo: InfoEventHandler;
}

function DiscountTableRows({ user: { discounts } }: UserProfileProps): JSX.Element {
  return (<>
    {
      (discounts || []).map(
        (discount, idx) =>
          <tr key={`discount-idx-${idx}`}>
            <td><strong>{discount.name}</strong>
            </td><td>{discount.value * 100}%</td>
          </tr>
      )}
  </>);
}

function UserAttributesTableRows({ user: { attributes } }: UserProfileProps): JSX.Element {
  return (<>
    {
      (attributes || []).map((attribute, idx) =>
        <tr key={`attribute-idx-${idx}`}>
          <td><strong>{attribute.name}</strong></td>
          <td>{attribute.description}</td>
        </tr>
      )}
  </>);
}

export default function ProfileInfo({ onError, onInfo }: ProfileInfoProperties): JSX.Element {
  const [linkCopyText, setLinkCopyText] = useState(TXT_LINK_COPY);
  const navigate = useNavigate();

  const [user, setUser] = useState<User>(DEFAULT_USER);
  const { loading: fetchingUser, refetch } = useQuery<UserResult>(USER_GET_ME_QUERY, {
    onCompleted: ({ me }) => setUser(me),
    onError,
  });
  const [updateUser] = useMutation(USER_UPDATE_MUTATION, {
    refetchQueries: [USER_GET_ME_QUERY],
    onError,
  });
  const [upload, { loading: uploading }] = useS3Uploader({ onError });
  const pathToStore: To =
  {
    pathname: URLS.STORE,
    search: createSearchParams({
      owner: user.uuid || "",
    }).toString(),
  };

  const refetchUser = useCallback(() => {
    refetch().then(({ data: { me } }) => setUser(me));
  }, [refetch]);

  const pathToStoreHref = useHref(pathToStore);
  const loading = fetchingUser || uploading;

  if (loading) {
    return (
      <div className="text-center">
        <Logo spin />
        <p><strong>Fetching your profile...</strong></p>
      </div>
    );
  }

  const name = user.alias || user.firstName;

  const onSubmit = (user: User, password: string, file: File | null): Promise<any> => {
    // NOTE passing "null" means the value will not get updated
    const variables = {
      newEmail: user.email || null,
      alias: user.alias || "",
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      phone: user.phone || "",
      tosAccepted: user.tosAccepted || false,
      imageUrl: user.imageUrl || "",
      password: password,
    };

    if (!file) {
      return updateUser({ variables })
        .then(refetchUser);
    }

    return upload("profile-pics", file)
      .then(imageUrl => {
        return updateUser({
          variables: {
            ...variables,
            imageUrl
          }
        });
      })
      .then(refetchUser)
      .catch(onError);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col className="h1 d-flex justify-content-center">
          {name ? `Welcome back, ${name}!` : "Welcome back!"}
        </Col>
      </Row>
      <Row>
        <Col>
          {
            user.email &&
            <ProfileForm
              user={user}
              buttonText="Update"
              passwordRequired={false}
              onError={onError}
              onInfo={onInfo}
              onSubmit={onSubmit}
              onUserUpdate={setUser}
            />
          }
        </Col>
        <Col>
          <Row>
            <Col className="h3">Store</Col>
          </Row>
          <Row>
            <Col className="d-flex">
              <Button onClick={() => navigate(pathToStore)}>
                Visit
              </Button>
              <ShareButton
                className="ms-2"
                tooltip={linkCopyText}
                spin={loading}
                disabled={loading}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.origin.concat(pathToStoreHref));
                  setLinkCopyText(TXT_LINK_COPIED);
                }}
                onMouseLeave={() => setLinkCopyText(TXT_LINK_COPY)}
              >
                Share
              </ShareButton>
            </Col>
          </Row>
          <Row>
            <Col className="h3">Attributes</Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <tbody>
                  <UserAttributesTableRows user={user} />
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col className="h3">
              Available Discounts
            </Col>
          </Row>
          <Row>
            <Col>
              <Table striped bordered hover>
                <tbody>
                  <DiscountTableRows user={user} />
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}