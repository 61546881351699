import { useState } from "react";
import { Form } from "react-bootstrap";
import checkPasswordComplexity from "../utils/checkPasswordComplexity";

interface PasswordCheckFormGroupProps {
  onChange: (password: string) => void;
  onError: (error: string) => void;
  disabled?: boolean;
}

export default function PasswordCheckFormGroup({ disabled, onChange, ...props }: PasswordCheckFormGroupProps) {
  const [passwordError, setPasswordError] = useState("");

  const onError = (error: string) => {
    props.onError(error);
    setPasswordError(error);
  }

  return (
    <>
      <Form.Group>
        <Form.Label className="h6 mt-3">
          <strong>Password </strong><span className="required-indicator">{passwordError || "*"}</span>
        </Form.Label>
        <Form.Control
          type="password"
          disabled={disabled}
          onChange={(e) => {
            const value = e.target.value;
            checkPasswordComplexity(value, onError);
            onChange(value);
          }}
        />
      </Form.Group>
    </>
  );
}